import React, { useState, useEffect } from 'react';
import { Card, Image, Row, Col, Input, Upload, Button, Spin, Empty, message } from 'antd';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { Resolution } from './Resolution';

const CompanyData = () => {
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState([]);
  const [resolution, setResolution] = useState([]);
  const [trustedBy, setTrustedBy] = useState(0);
  const [editTrustedBy, setEditTrustedBy] = useState(0);
  const [files, setFiles] = useState([]);

  const getDetails = () => {
    request('/api/companyDetails', 'GET')
      .then((res) => {
        setLogos(res.logos);
        setTrustedBy(res.trustedBy);
      })
      .catch((err) => console.log(err));

    request('/api/companyDetails/resolution', 'GET')
      .then((res) => {
        setResolution(res);
      })
      .catch((err) => console.log(err));
  };

  const uploadLogos = () => {
    let index = 0;
    files.forEach((logo) => {
      setLoading(true);
      console.log(files);
      request('/api/companyDetails', 'POST', {
        formData: {
          logos: logo.originFileObj,
        },
      })
        .then((res) => {
          console.log(res);
          setLogos([...logos, res]);
          console.log(files.length, index);
          index += 1;
          if (files.length === index) {
            setFiles([]);
            window.location.reload();
          }
        })
        .catch((err) => console.log('err', err))
        .finally(() => setLoading(false));
    });
  };

  const deleteLogo = (id) => {
    setLoading(true);
    request(`/api/companyDetails/${id}`, 'DELETE')
      .then(() => {
        setLogos(logos.filter((logo) => logo.id !== id));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const updateTrustedBy = () => {
    console.log(editTrustedBy);
    request('/api/companyDetails/trustedBy', 'PATCH', {
      data: {
        trustedBy: parseInt(editTrustedBy, 10),
      },
    })
      .then(() => {
        message.success('Trusted By Number Edited');
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Company Data"
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Resolution />
          <br />
          <Card title={`Trusted By ${trustedBy.trustedBy} Companies`}>
            <Row>
              <Col span={20}>
                <Input
                  placeholder="Enter Trusted By Number..."
                  onChange={(e) => setEditTrustedBy(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <Button onClick={() => updateTrustedBy()} type="primary">
                  Update
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Upload Company Data">
            {loading ? (
              <Col span={24}>
                <center>
                  <Spin size="large" />
                </center>
              </Col>
            ) : (
              <>
                <Col span={24}>
                  <Row>
                    <Upload
                      onChange={(e) => {
                        console.log(e);
                        setFiles([...files, ...e.fileList]);
                      }}
                      listType="picture"
                      beforeUpload={() => false}
                      multiple
                    >
                      <Button style={{ width: 450 }}>Upload Company Logos</Button>
                    </Upload>
                    <Button
                      type="primary"
                      disabled={!files.length > 0}
                      onClick={() => uploadLogos()}
                    >
                      Upload
                    </Button>
                  </Row>
                </Col>
                <br />
                <Row style={{ height: 400, overflowY: 'scroll' }}>
                  {logos.length > 0 ? (
                    logos.map((logo) => (
                      <Col
                        key={logo.id}
                        span={6}
                        style={{
                          border: '1px solid #f2f2f2',
                        }}
                      >
                        <Col
                          style={{
                            padding: 20,
                            height: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'baseline',
                          }}
                        >
                          <Image
                            src={logo.logos}
                            style={{
                              height: `${resolution.height}px`,
                              width: `${resolution.width}px`,
                            }}
                          />
                        </Col>
                        <br />
                        <Button
                          type="primary"
                          onClick={() => deleteLogo(logo.id)}
                          style={{ width: '100%' }}
                        >
                          Delete
                        </Button>
                      </Col>
                    ))
                  ) : (
                    <Col span={24}>
                      <center>
                        <Empty />
                      </center>
                    </Col>
                    // <>No Logos Found</>
                  )}
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </HCLayout>
  );
};

export { CompanyData };
