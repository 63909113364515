/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Radio,
  Input,
  Form,
  Row,
  Col,
  Drawer,
  Modal,
  Image,
  message,
  Tabs,
  Alert,
  Switch,
  Tooltip,
  DatePicker,
  Select,
} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  ExclamationOutlined,
  CloseOutlined,
  ReloadOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  FilterFilled,
} from '@ant-design/icons';

import QS from 'query-string';

import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';
import { Desc } from './Common/Description';
import { request } from '../service/common';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import { AuthContext } from '../context/Authcontext';
import './Layout/style.css';
import { ROLE } from './Hepler';

const { Option } = Select;

const User = () => {
  const { TabPane } = Tabs;

  const [user, setUser] = useState([]);

  const [loading, setLoading] = useState(true);

  const [drawer, setDrawer] = useState(false);

  const [siderProps, setSiderProps] = useState({});

  const [editData, setEditData] = useState({});

  const [editModalVisiblity, setEditModalVisiblity] = useState(false);

  const { userContext } = useContext(AuthContext);

  const [bannedUsers, setBannedUsers] = useState(0);

  const [totalUsers, setTotalUsers] = useState(0);

  const [userWithDPs, setUserWithDPs] = useState(0);

  const [showTrash, setShowTrash] = useState(false);

  const [page, setPage] = useState(1);

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [filters, setFilters] = useState({});

  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const [guestUsersCount, setGuestUsersCount] = useState(0);

  const refreshTable = (queryString) => {
    setLoading(true);

    request(
      queryString
        ? `/api/app-user/users?page=${page}&${queryString}`
        : `/api/app-user/users?page=${page}`,
      'GET',
    )
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            item.key = item.id;
          });
          setUser(data);
        } else {
          if (page === 1) setUser(data);
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const requestsCaller = () => {
    setLoading(true);

    request(`/api/app-user/users`, 'GET')
      .then(async (data) => {
        data.map((item) => {
          item.key = item.id;
        });
        setUser(data);
        setLoading(false);
      })
      .catch((err) => {
        throw err;
      });
  };
  const getTrash = (val) => {
    setShowTrash(val);
    setPage(1);
    onTableFilterChange({ isBanned: val });
    if (val) {
      setFilterChange();
    } else {
      clearFilter('isBanned');
    }
  };

  useEffect(() => {
    requestsCaller();
    request(`/api/count/users`, 'GET')
      .then(async (data) => {
        setTotalUsers(data.totalUsers);
        setBannedUsers(data.bannedUsers);
      })
      .catch((err) => {
        throw err;
      });

    request(`/api/getAll/userWithDPs`, 'GET')
      .then(async (data) => {
        console.log('dp data', data);

        setUserWithDPs(data.length);
      })
      .catch((err) => {
        throw err;
      });

    request(`/api/getAll/guests`, 'GET')
      .then(async (data) => {
        console.log('dp data', data);

        setGuestUsersCount(data.length);
      })
      .catch((err) => {
        throw err;
      });
    return () => {
      setTotalUsers();
      setBannedUsers();
      setGuestUsersCount();
    };
  }, []);

  useEffect(() => {
    refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
  }, [page]);

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
  }, [isFilterChanged]);

  const onDateChange = (date, dateString) => {
    if (dateString.length) {
      setPage(1);
      onTableFilterChange({
        date: dateString,
      });
    } else clearFilter('date');
    setFilterChange();
  };

  const onTableFilterChange = (query) => {
    setFilters({ ...filters, ...query });
  };

  const clearFilter = (type) => {
    setPage(1);
    setFilters({ ...filters, [type]: undefined });
    setIsFilterChanged(true);
  };

  const setFilterChange = () => {
    setIsFilterChanged(true);
  };

  const finalDelete = (record) => {
    Modal.confirm({
      title: 'This action is not reversable',
      icon: <ExclamationOutlined />,
      content: 'Please be careful while deleteting permanently. this action is undoable',
      onOk: () => {
        setLoading(true);
        request(`/api/app-user/delete?userId=${record.id}`, 'DELETE')
          .then(() => {
            setUser(user.filter((usr) => usr.id !== record.id));
            setLoading(false);
          })
          .catch((err) => console.log(err));
      },
      okText: 'Delete',
    });
  };

  const multiPleBan = () => {
    console.log(selectedIds);

    request('/api/user/multiple', 'PATCH', {
      data: {
        ids: selectedIds,
        ban: true,
      },
    })
      .then(() => {
        refreshTable();
      })
      .catch((err) => console.log(err));
  };

  const actionBtn = [
    <Row gutter={16} key="1">
      <Col>
        <div style={{ paddingTop: 5 }}>
          Trash: &nbsp;
          <Switch defaultChecked={showTrash} onChange={getTrash} />
        </div>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() => {
            setPage(1);
            requestsCaller();
          }}
        >
          <ReloadOutlined />
        </Button>
      </Col>

      <Col>
        <Button
          type="primary"
          onClick={() => {
            multiPleBan();
          }}
        >
          <DeleteOutlined />
        </Button>
      </Col>
      <Col>
        {userContext.access['download'][0] ? (
          <Link to="/export/users" target="_blank">
            <Button
              className="w-44"
              type="primary"
              style={{ border: 'none' }}
              onClick={() => {
                message.success('The file is downloading');
              }}
            >
              Export to CSV
            </Button>
          </Link>
        ) : null}
      </Col>
    </Row>,
  ];

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  const onEdit = (record) => {
    setEditModalVisiblity(true);
    setEditData(record);
  };

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to Ban this user',
      okText: 'Yes, Ban',
      onOk: () => {
        setLoading(true);
        request(`/api/app-user?userId=${record.id}`, 'DELETE')
          .then(async () => {
            setUser(
              user.map((user) => (user.id === record.id ? { ...user, isBanned: true } : user)),
            );

            setBannedUsers(bannedUsers + 1);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          });
      },
    });
  };

  const onUnban = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to un-ban this user',
      okText: 'Yes, Un-ban',
      onOk: () => {
        setLoading(true);
        request(`/api/app-user/restore?userId=${record.id}`, 'PATCH')
          .then(async () => {
            setUser(user.filter((user) => user.id !== record.id));
            setBannedUsers(bannedUsers - 1);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          });
      },
    });
  };

  const onDrawerClose = () => {
    setSiderProps({});
    setDrawer(false);
  };
  const onDrawerOpen = (record) => {
    setSiderProps({
      title: record.name,
      data: record,
    });
    setDrawer(true);
  };

  const onEditModalClose = () => {
    setEditModalVisiblity(false);
    setEditData({});
  };

  const editModalSave = () => {
    setEditModalVisiblity(false);
    setLoading(true);
    request(`/api/app-user?userId=${editData.id}`, 'PATCH', {
      data: {
        name: editData.name,
        gender: editData.gender.toString(),
        age: editData.age,
      },
    })
      .then(async () => {
        setUser(user.map((item) => (item.id === editData.id ? editData : item)));
        setLoading(false);
        setEditData({});
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  const data = siderProps.data || {};

  const columns = [
    {
      key: 'Name',
      title: 'Name',
      render: (record) => record.name,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.name}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  name: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('name')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'Role',
      title: 'Role',
      render: (record) => ROLE[record.role],
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Select
              style={{ width: 100 }}
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({ role: e });
                setFilterChange();
              }}
            >
              {Object.entries(ROLE).map((role) => {
                console.log(role);
                return <Option value={role[0]}>{role[1]}</Option>;
              })}
            </Select>
          </Col>

          <Col>
            <Button
              onClick={() => clearFilter('role')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <FilterFilled />,
    },

    {
      key: 'Contractor',
      title: 'Contractor',
      render: (record) => (record.isContractor ? 'Yes' : 'No'),
    },
    {
      key: 'Phone',
      title: 'Phone',
      render: (record) => record.phone,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.phone}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  phone: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('phone')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'gender',
      title: 'Gender',
      render: (record) => (record.gender === 2 ? 'Female' : 'Male'),
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  gender: 'asc',
                  age: undefined,
                });
                setFilterChange();
              }}
              icon={<CaretUpOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  gender: 'desc',
                  age: undefined,
                });
                setFilterChange();
              }}
              icon={<CaretDownOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('gender')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: 'Age',
      title: 'Age',
      render: (record) => record.age,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  age: 'asc',
                  gender: undefined,
                });
                setFilterChange();
              }}
              icon={<CaretUpOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  age: 'desc',
                  gender: undefined,
                });
                setFilterChange();
              }}
              icon={<CaretDownOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('age')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
    },

    {
      key: 'isGuest',
      title: 'Is Guest',
      render: (record) => (record.isGuest ? 'Yes' : 'no'),
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  isGuest: true,
                });
                setFilterChange();
              }}
              icon={<CaretUpOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  isGuest: false,
                });
                setFilterChange();
              }}
              icon={<CaretDownOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('isGuest')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: 'Last Active',
      title: 'Last Active',
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.lastActive).toLocaleDateString()} ${new Date(
            record.lastActive,
          ).toLocaleTimeString()}`}
        >
          {`${new Date(record.lastActive).toLocaleDateString()}`}
        </Tooltip>
      ),
      filterDropdown: () => {},
      filterIcon: () => <DatePicker style={{ width: 50 }} onChange={onDateChange} />,
    },
    {
      key: 'createdAt',
      title: 'Reg. Date',
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.createdAt).toLocaleDateString()} ${new Date(
            record.createdAt,
          ).toLocaleTimeString()}`}
        >
          {`${new Date(record.createdAt).toLocaleDateString()}`}
        </Tooltip>
      ),
      filterDropdown: () => {},
      filterIcon: () => <DatePicker style={{ width: 50 }} onChange={onDateChange} />,
    },
    {
      key: 7,
      title: 'Action',
      width: 200,
      render: (record) => (
        <>
          <EyeOutlined
            title="View"
            style={innerTableActionBtnDesign}
            onClick={() => {
              onDrawerOpen(record);
            }}
          />
          {userContext.access['labourers'][2] ? (
            <EditOutlined
              title="Edit"
              style={innerTableActionBtnDesign}
              onClick={() => onEdit(record)}
            />
          ) : null}
          {record.isBanned === true ? (
            <ReloadOutlined
              title="Unban"
              style={innerTableActionBtnDesign}
              onClick={() => onUnban(record)}
            />
          ) : userContext.access['labourers'][3] ? (
            <DeleteOutlined
              title="Ban"
              style={innerTableActionBtnDesign}
              onClick={() => onDelete(record)}
            />
          ) : null}

          {showTrash ? (
            <DeleteOutlined
              title="Delete Permanently"
              style={innerTableActionBtnDesign}
              onClick={() => finalDelete(record)}
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Users"
      actions={actionBtn}
    >
      <Row gutter={[24, 24]} className="p-3">
        <Col span={12} xs={24} md={6} sm={12} lg={6} className="gutter-row ">
          <div className="tileStyle">
            <h2>Total Users</h2>
            <span>{`${totalUsers}`}</span>
          </div>
        </Col>
        <Col xs={24} md={6} sm={12} lg={6}>
          <div className="tileStyle">
            <h2>Banned Users</h2>
            <span>{`${bannedUsers}`}</span>
          </div>
        </Col>

        <Col xs={24} md={6} sm={12} lg={6}>
          <div className="tileStyle">
            <h2>Guest Users</h2>
            <span>{`${guestUsersCount}`}</span>
          </div>
        </Col>

        <Col xs={24} md={6} sm={12} lg={6}>
          <div className="tileStyle">
            <h2> Users with DPs</h2>
            <span>{`${userWithDPs}`}</span>
          </div>
        </Col>
      </Row>
      {showTrash ? (
        <Alert
          type="warning"
          message="User in trash will be removed automatically after 30 days"
          showIcon
        />
      ) : null}
      <DataTable
        usersData={user}
        searchable={false}
        differRows
        pagination={false}
        rowSelection={{
          selectedIds,
          onChange: (selectedRowKeys) => {
            setSelectedIds(selectedRowKeys);
          },
        }}
        loading={loading}
        columns={columns}
      />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
      <Drawer
        title={siderProps.title}
        width="750px"
        placement="right"
        onClose={onDrawerClose}
        visible={drawer}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="User information" key="1">
            <Row>
              <Col span={12} lg={12} md={12} sm={32} xs={32}>
                <Desc title="Name" content={data.name} />
                <Desc title="Phone" content={data.phone} />
              </Col>
              <Col span={12} lg={12} md={12} sm={32} xs={32}>
                <Desc title="Age" content={data.age} />
                <Desc title="Gender" content={data.gender === 2 ? `Female` : 'Male'} />
              </Col>
              <Col span={12} lg={12} md={12} sm={32} xs={32}>
                <Desc
                  title="Role"
                  content={
                    data.role === 3
                      ? `Mill owner`
                      : data.role === 2
                      ? 'Middleman'
                      : data.role === 1
                      ? 'Labour'
                      : 'Partially registered'
                  }
                />
                <Desc
                  title="Locale"
                  content={data.locale === 3 ? `Tamil` : data.locale === 2 ? 'Hindi' : 'English'}
                />
              </Col>
              <Col span={32} className="p-3 mt-3">
                <h2>
                  <b>Image : </b>
                </h2>
                <Image preview={false} src={data.imageUrl} height="200px" width="200px" />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Drawer>
      <Modal
        title="Edit User"
        visible={editModalVisiblity}
        onCancel={onEditModalClose}
        onOk={editModalSave}
        okText="Update User Info"
      >
        <Form.Item label="Name">
          <Input
            title="Name "
            value={editData.name}
            onChange={(e) => {
              setEditData({
                ...editData,
                name: e.target.value,
              });
            }}
            placeholder="Enter Name"
            required
          />
        </Form.Item>
        <Form.Item label="Gender">
          <Radio.Group
            onChange={(e) => {
              setEditData({
                ...editData,
                gender: e.target.value === 'Male' ? 1 : 2,
              });
            }}
            value={editData.gender === 1 ? 'Male' : 'Female'}
          >
            <Radio value="Male">Male</Radio>
            <Radio value="Female">Female</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Age">
          <Input
            title="Age "
            value={editData.age}
            onChange={(e) => {
              setEditData({
                ...editData,
                age: e.target.value,
              });
            }}
            placeholder="Enter Age"
            required
          />
        </Form.Item>
      </Modal>
    </HCLayout>
  );
};

export { User };
