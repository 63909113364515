import { PlayCircleFilled } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Upload,
} from 'antd';
import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';

const { Option } = Select;

const Videos = () => {
  const [loading, setLoading] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoPrev, setVideoPrev] = useState(false);
  const [playVideo, setPlayVideo] = useState('');
  const [newLabel, setNewLabel] = useState('');
  const [videoCategories, setVideoCategories] = useState([]);

  const getVideoCategories = () => {
    setLoading(true);

    request('/api/videos/getVideoCategories', 'GET')
      .then((res) => {
        console.log(res);
        setVideoCategories(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getVideos = () => {
    setLoading(true);

    request('/api/videos', 'GET')
      .then((res) => {
        console.log(res);
        setVideos(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const addCategory = (e) => {
    e.preventDefault();
    console.log(e);
    setLoading(true);

    request('/api/videos/category/add', 'POST', {
      data: {
        label: newLabel,
      },
    }).then((res) => {
      setVideoCategories([...videoCategories, res]);
      message.success('Video Category Created');
      setCategoryModal(false);
      setNewLabel('');

      setLoading(false);
    });
  };

  const addVideo = (e) => {
    console.log(e);

    setLoading(true);

    request(`/api/videos/${e.categoryId}`, 'POST', {
      formData: {
        title: e.title,
        description: e.description,
        link: e.link,
        thumbnail: e.thumbnail.file.originFileObj,
      },
    })
      .then((res) => {
        console.log(res);
        setVideoModal(false);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const filterVideos = (e) => {
    setLoading(true);

    request(`/api/videos/sortByCategory/${e}`, 'GET')
      .then((res) => {
        setVideos(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteVideo = (e) => {
    setLoading(true);
    request(`/api/videos/deleteVideo/${e}`, 'DELETE')
      .then(() => {
        setVideos(videos.filter((vdo) => vdo.id !== e));
        setLoading(false);
        message.success('Video Deleted');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVideoCategories();
    getVideos();
  }, []);

  const categoryHeaders = [
    {
      key: 1,
      title: 'Category',
      render: (record) => record.label,
    },
    {
      key: 2,
      title: 'Created On',
      render: (record) => record.createAt.split('T')[0],
    },
  ];

  const actionBtn = [
    <Row gutter={[16, 16]}>
      <Col>
        <Button type="primary" onClick={() => setCategoryModal(true)}>
          Add New Category
        </Button>
      </Col>
      <Col>
        <Button type="primary" onClick={() => setVideoModal(true)}>
          Upload Video
        </Button>
      </Col>
    </Row>,
  ];

  return (
    <HCLayout title="In App Videos" actions={actionBtn}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Videos" key="1">
          <Select
            onChange={(e) => filterVideos(e)}
            placeholder="Select A Video Category"
            style={{ width: 300 }}
          >
            {videoCategories.map((category) => (
              <Option value={category.id}>{category.label}</Option>
            ))}
          </Select>

          <Button onClick={() => getVideos()}>Clear</Button>
          <br />
          <br />
          {loading ? (
            <center>
              <Spin />
            </center>
          ) : (
            <Row gutter={[16, 16]}>
              {videos.map((video) => (
                <Col span={6} md={6} lg={6}>
                  <Card
                    actions={[
                      <p>{video.createdAt.split('T')[0]}</p>,
                      <Button onClick={() => deleteVideo(video.id)}>delete</Button>,
                    ]}
                  >
                    {/* <YouTube videoId={video.link.split('?v=')[1]} /> */}

                    {/* <iframe src={`https://youtube.com/embed/`} title="Play" /> */}
                    <Image
                      src={video.thumbnail}
                      preview={false}
                      style={{ width: '260px', height: '180px' }}
                    />

                    <PlayCircleFilled
                      style={{
                        position: 'absolute',
                        left: '130px',
                        top: '100px',
                        fontSize: 40,
                        color: '#f2f2f2',
                      }}
                      onClick={() => {
                        setPlayVideo(video.link.split('?v=')[1]);
                        setVideoPrev(true);
                      }}
                    />

                    <p>
                      <b>Title : </b>
                      {video.title}
                      <br />
                      <b>Video Cateory : </b>

                      {video.videoCategory.label}
                    </p>
                  </Card>
                </Col>
              ))}

              <Modal
                title="Add New Category"
                onCancel={() => setCategoryModal(false)}
                footer={[]}
                visible={categoryModal}
              >
                <form onSubmit={(e) => addCategory(e)}>
                  <Input
                    name="label"
                    value={newLabel}
                    onChange={(e) => setNewLabel(e.target.value)}
                    placeholder="Video Category"
                  />
                  <br />
                  <br />
                  <Button htmlType="submit" type="primary">
                    Add New Video Category
                  </Button>
                </form>
              </Modal>

              <Modal
                title="Add New Video"
                onCancel={() => setVideoModal(false)}
                footer={[]}
                visible={videoModal}
              >
                <Form layout="vertical" onFinish={addVideo}>
                  <Form.Item
                    name="categoryId"
                    label="Video Category"
                    required
                    rules={[
                      {
                        type: 'number',
                        required: true,
                        messaage: 'Video category is required',
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => console.log(e)}
                      placeholder="Select A Video Category"
                      style={{ width: 300 }}
                    >
                      {videoCategories.map((category) => (
                        <Option value={category.id}>{category.label}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="title"
                    label="Title"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Video title is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="description"
                    required
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: 'Video description is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="link"
                    required
                    label="Link"
                    rules={[
                      {
                        required: true,
                        message: 'Video link is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="thumbnail"
                    label="Thumbnail"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Thumbnail is required',
                      },
                    ]}
                  >
                    <Upload beforeUpload={false}>
                      <Button>Upload</Button>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Add New Video
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>

              <Modal
                footer={[]}
                width={720}
                visible={videoPrev}
                onCancel={() => {
                  setVideoPrev(false);
                  setPlayVideo('');
                }}
              >
                <YouTube videoId={playVideo} />
              </Modal>
            </Row>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Video Categories" key="2">
          <DataTable usersData={videoCategories} columns={categoryHeaders} />
        </Tabs.TabPane>
      </Tabs>
    </HCLayout>
  );
};

export { Videos };
