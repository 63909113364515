/* eslint-disable arrow-body-style */
import { CopyOutlined, FileAddFilled, ReloadOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, message, Row, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';

const Bucket = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [bucket, setBucket] = useState([]);

  const getBucket = () => {
    setLoading(true);
    request('/api/bucket', 'GET')
      .then((res) => {
        setBucket(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => getBucket(), []);

  const uploadFile = () => {
    setLoading(true);
    request('/api/bucket', 'POST', {
      formData: { file },
    })
      .then((res) => {
        console.log(res);
        setFile(null);
        setBucket([res, ...bucket]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const cols = [
    {
      key: '1',
      title: 'Image',
      render: (record) => <Avatar src={record.image} />,
    },
    {
      key: '2',
      title: 'Link',
      render: (record) => record.image,
    },
    {
      key: '3',
      title: 'Added On',
      render: (record) => record.createdAt,
    },
    {
      key: '4',
      title: 'Action',
      render: (record) => (
        <CopyOutlined
          style={innerTableActionBtnDesign}
          onClick={() => {
            navigator.clipboard.writeText(record.image);
            message.info('Link Copied');
          }}
        />
      ),
    },
  ];

  return (
    <HCLayout title="Bucket">
      <Card>
        <Row>
          <Upload
            beforeUpload={() => false}
            listType="picture"
            onChange={(e) => {
              console.log(e.file);
              setFile(e.file);
            }}
          >
            <Button type="ghost" style={{ width: 300 }}>
              <FileAddFilled />
              Add files
            </Button>
          </Upload>
          <Button type="primary" onClick={() => uploadFile()}>
            Upload
          </Button>
        </Row>
      </Card>
      <br />
      <Card
        title="Bucket List"
        extra={<ReloadOutlined style={innerTableActionBtnDesign} onClick={() => getBucket()} />}
      >
        <DataTable loading={loading} pagination={false} columns={cols} usersData={bucket} />
      </Card>
    </HCLayout>
  );
};

export { Bucket };
