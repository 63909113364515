/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Card, Select, Button, Input, Form, List, Col, Alert, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { HCLayout } from './Layout/HCLayout';
import { request } from '../service/common';
import { DataTable } from './Table/Table';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import { DEPENDENTS, DROPDOWNTYPES, EXPERIENCE, GENDER } from './Hepler';

const { Option } = Select;

const inputStyle = {
  width: 200,
};

const SystemTags = () => {
  const [tags, setTags] = useState([]);
  const [dependent, setDependent] = useState([]);
  const [sysTags, setSysTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [currentDependent, setCurrentDependent] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [arrayOfDeps] = useState(Object.entries(DEPENDENTS));

  const getTags = () => {
    request('/api/tags', 'GET')
      .then((res) => setTags(res))
      .catch((err) => console.log(err));
  };

  const getSystemTags = () => {
    setLoading(true);
    request('/api/tags/systemTags', 'GET')
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        setSysTags(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const getDependents = () => {
    request('/api/tags/dependents', 'GET')
      .then((res) => setDependent(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTags();
    getSystemTags();
    getDependents();
    return () => {
      setTags();
      setSysTags();
      setDependent();
    }
  }, []);

  const addTags = (e) => {
    setCurrentTags([...currentTags, e]);
    console.log(currentTags);
    form.resetFields();
  };

  const saveSystemTags = () => {
    setLoading(true);
    request('/api/tags/systemTags', 'POST', { data: currentTags })
      .then(() => {
        setSysTags([...sysTags, ...currentTags]);
        setCurrentTags([]);
        message.success('Tags Added Successfuly');
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const cols = [
    {
      key: '1',
      title: 'Dependent',
      render: (record) => record.dependent,
    },
    {
      key: '1',
      title: 'Operator',
      render: (record) => record.operator,
    },
    {
      key: '1',
      title: 'Label',
      render: (record) => record.label,
    },

    {
      key: '1',
      title: 'Tags',
      render: (record) => record.tags.join(', '),
    },
  ];

  return (
    <HCLayout title="System Tags">
      <Alert
        message="Note: Please Select Equal To Operator For Experience Required And Gender Preference As They Are Enums"
        type="warning"
        showIcon
      />
      <br />
      <Card
        title="Add New System Tags"
        extra={
          <Button
            type="primary"
            disabled={currentTags.length === 0}
            onClick={() => saveSystemTags()}
          >
            Save System Tags
          </Button>
        }
      >
        <div style={{ fontSize: 18, textTransform: 'uppercase' }}>
          <Form form={form} onFinish={(e) => addTags(e)} layout="inline">
            If a &nbsp;
            <Form.Item name="dependent" rules={[{ required: true, message: 'Select Dependent' }]}>
              <Select
                onChange={(e) => setCurrentDependent(e)}
                placeholder="Dependent"
                style={inputStyle}
              >
                {dependent.map((dep) => (
                  <Option key={dep.label} value={dep.label}>
                    {DEPENDENTS[dep.label]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            is&nbsp; &nbsp;
            <Form.Item name="operator" rules={[{ required: true, message: 'Select Operator' }]}>
              <Select placeholder="Operator" style={inputStyle}>
                <Option value="=="> Equal To </Option>
                <Option value=">"> Grater Then </Option>
                <Option value="<"> Less Then </Option>
                <Option value="!=="> Not Equal TO </Option>
              </Select>
            </Form.Item>
            &nbsp;
            {arrayOfDeps.map((deps) => {
              if (deps[0] === currentDependent) {
                if (DROPDOWNTYPES.includes(deps[0])) {
                  if (deps[0] === 'expReq') {
                    return (
                      <Form.Item
                        name="label"
                        rules={[{ required: true, message: 'Enter A Value' }]}
                      >
                        <Select placeholder="Value" style={inputStyle}>
                          {Object.entries(EXPERIENCE).map((exp) => (
                            <Option value={exp[0]}>{exp[1]}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }
                  // eslint-disable-next-line no-else-return
                  else if (deps[0] === 'genderPreference') {
                    return (
                      <Form.Item
                        name="label"
                        rules={[{ required: true, message: 'Enter A Value' }]}
                      >
                        <Select placeholder="Value" style={inputStyle}>
                          {Object.entries(GENDER).map((exp) => (
                            <Option value={exp[0]}>{exp[1]}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }

                  // <Input placeholder="Value" style={inputStyle} />;
                }
                return (
                  <Form.Item name="label" rules={[{ required: true, message: 'Enter a value' }]}>
                    <Input placeholder="Enter Value" style={inputStyle} />
                  </Form.Item>
                );
              }
              return '';
            })}
            ,then add &nbsp;&nbsp;
            <Form.Item name="tags" rules={[{ required: true, message: 'Select Tags' }]}>
              <Select style={inputStyle} placeholder="Tags" mode="multiple">
                {tags.map((tag) => (
                  <Option key={tag.tag} value={tag.tag}>
                    {tag.tag}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            Tags in job. &nbsp;
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form>
        </div>
        <Col style={{ paddingTop: 20 }}>
          {currentTags.length > 0 ? (
            <List
              dataSource={currentTags}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    description={`If ${DEPENDENTS[item.dependent]} is ${item.operator} ${
                      item.dependent === 'expReq'
                        ? EXPERIENCE[item.label]
                        : item.dependent === 'genderPreference'
                        ? GENDER[item.label]
                        : item.label
                    } then job will have ${item.tags} tags`}
                  />

                  <Button
                    onClick={() =>
                      setCurrentTags(currentTags.filter((tg) => tg.dependent !== item.dependent))
                    }
                    type="danger"
                  >
                    Remove
                  </Button>
                </List.Item>
              )}
            />
          ) : null}
        </Col>
      </Card>
      <br />
      <Card
        title="System Tags"
        extra={<ReloadOutlined onClick={() => getSystemTags()} style={innerTableActionBtnDesign} />}
      >
        <DataTable pagination={false} columns={cols} usersData={sysTags} loading={loading} />
      </Card>
    </HCLayout>
  );
};

export { SystemTags };
