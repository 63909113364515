/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Input, Row, Col, Tag, message, Switch, Typography } from 'antd';
import {
  CheckOutlined,
  // CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  // EditOutlined,
} from '@ant-design/icons';
import { request } from '../service/common';
import { DataTable } from './Table/Table';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import { TagStatus } from '../const';

const { Text } = Typography;

const Tags = () => {
  const tagInput = useRef();

  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState([]);

  const [currentTags, setCurrentTags] = useState([]);

  const fetchData = () => {
    setLoading(true);
    request('/api/tags/all', 'GET')
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        setTags(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleTags = (e) => {
    // eslint-disable-next-line no-unused-expressions
    currentTags.includes(e.target.value)
      ? message.error('Tag already added')
      : setCurrentTags([...currentTags, e.target.value]);
  };

  const handleClose = (tag) => {
    console.log(tag);
    setCurrentTags(currentTags.filter((mTag) => mTag !== tag));
  };

  const uploadTags = () => {
    setLoading(true);
    request('/api/tags', 'POST', {
      data: {
        tags: currentTags,
      },
    })
      .then(async (res) => {
        console.log(res);
        setTags([...tags, ...res]);
        console.log(tags);
        setCurrentTags([]);
        setLoading(false);
      })
      .catch((err) => {
        message.warn('Failed to add tags. Make sure you enter unique tag');
        setLoading(false);
        console.log(err);
      });
  };

  const updateTag = (status, id) => {
    console.log(status, id);
    setLoading(true);
    request(`/api/tags/${id}`, 'PATCH', {
      data: {
        status: status ? TagStatus.Active : TagStatus.Inactive,
      },
    }).then(async (res) => {
      console.log(res);
      setTags(tags.map((tag) => (tag.id === res.id ? res : tag)));
      setLoading(false);
    });
  };

  const deleteTag = (id) => {
    setLoading(true);
    request(`/api/tags/${id}`, 'DELETE')
      .then(async () => {
        setTags(tags.filter((tag) => tag.id !== id));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const cols = [
    {
      key: 'tag',
      title: 'Tag',
      render: (record) => record.tag,
    },
    {
      key: 'status',
      title: 'Status',
      render: (record) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={record.status === TagStatus.Active}
          onChange={(e) => updateTag(e, record.id)}
        />
      ),
    },
    {
      key: 'Action',
      title: 'Status',
      render: (record) => (
        <Row key="1">
          {/* <Col>
            <EditOutlined style={innerTableActionBtnDesign} />
          </Col> */}
          <Col>
            <DeleteOutlined
              onClick={() => deleteTag(record.id)}
              style={innerTableActionBtnDesign}
            />
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    return () => {
      setTags();
    };
  }, []);

  return (
    <>
      <Card title="Tags">
        <Text type="secondary">
          {' '}
          Enter Tag name and hit <code>Enter</code> key to add tags. then press save button to save
          them. <b> Duplicate Tags are not allowed</b>
        </Text>

        <Row key="2">
          <Col span={[24]} style={{ paddingTop: 5, paddingBottom: 20 }}>
            {currentTags.map((cTag) => (
              <Tag
                color="#108ee9"
                style={{ paddingLeft: 10, paddingRight: 15, margin: 2 }}
                key={cTag}
                closable
                closeIcon={
                  <CloseOutlined style={{ color: 'white', fontSize: 18, marginLeft: 8 }} />
                }
                onClose={() => handleClose(cTag)}
              >
                {cTag}
              </Tag>
            ))}
          </Col>
          <Col span={20}>
            <Input ref={tagInput} onPressEnter={(e) => handleTags(e)} />
          </Col>
          <Col span={4}>
            <Button type="primary" onClick={() => uploadTags()}>
              Save
            </Button>
          </Col>
        </Row>
      </Card>
      <DataTable usersData={tags} columns={cols} loading={loading} pagination={false} />
    </>
  );
};

export { Tags };
