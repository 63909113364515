import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { SHCLayout } from './Components/Layout/SHCLayout';
import { Login } from './Components/Login';
import { Dashboard } from './Components/Common/Dashboard';
import { User } from './Components/User';
import { Labour } from './Components/Labour';
import { MillTypes } from './Components/MillTypes';
import { Factory } from './Components/Factory';
import { Salary } from './Components/Salary';
import { Commission } from './Components/Commission';
import { MiddleMan } from './Components/MiddleMan';
import { Admin } from './Components/Admin';
import { Approval } from './Components/Approval';

import { AppData } from './Components/app-data';
import { ServerAvailability } from './Components/ServerAvailability';
import { Session } from './Components/Session';
import { Worker } from './Components/Worker';
import { Referral } from './Components/Referral';
import { Jobs } from './Components/Jobs';
// import { Applications } from './Components/Applications';
import { Support } from './Components/Support';
import { Notifications } from './Components/Notifications';
import 'antd/dist/antd.css';

import { logout } from './service/auth';
import { AuthContext, AuthProvider } from './context/Authcontext';
import { MiddleManLabours } from './Components/MiddlemanLabours';
import { Flyers } from './Components/flyers';
import { Inactives } from './Components/Inactives';
import { MappedUsers } from './Components/MappedUsers';
import { Billing } from './Components/Billing';
import { JobMarkets } from './Components/JobMarkets';
import { SystemTags } from './Components/System-tags';
import { PartnerMills } from './Components/PartnerMills';
import { AppVersion } from './Components/AppVersion';
import { LocationHistory } from './Components/Location-history';
import { CompanyData } from './Components/Company-data';
import { ExportCSV } from './Components/ExportCSV';
import { Bucket } from './Components/Bucket';
import { CallDetails } from './Components/Call-details';
import { Uninstalls } from './Components/uninstalled-users';
import { PersonalisedNotification } from './Components/Personalised-Notifications';
import { NonVarifiedMills } from './Components/NonVarifiedMills';
import { JobCategories } from './Components/jobCatagories';
import { Logs } from './Components/Logs';
import { Videos } from './Components/videos';
import { EventNotification } from './Components/EventNotification';

const Logout = () => {
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const { changeContext } = useContext(AuthContext);
  useEffect(() => {
    logout()
      .catch(() => console.log('err'))
      .finally(() => {
        changeContext(undefined);

        setLogoutSuccess(true);
      });
  }, []);
  if (!logoutSuccess) return null;

  return <Redirect to="/login" />;
};

export const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/export/:reqData" component={ExportCSV} />
      <AuthProvider>
        <SHCLayout>
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/" exact component={Dashboard} />
          <Route path="/user" component={User} />
          <Route path="/labourer" component={Labour} />
          <Route path="/factory" component={Factory} />
          <Route path="/nonVarifiedMills" component={NonVarifiedMills} />
          <Route path="/partner-mills" component={PartnerMills} />
          <Route path="/version" component={AppVersion} />
          <Route path="/salary" component={Salary} />
          <Route path="/commission" component={Commission} />
          <Route path="/agent" component={MiddleMan} />
          <Route path="/agent-labourer" component={MiddleManLabours} />
          {/* <Route path="/milltype" component={MillTypes} /> */}
          <Route path="/jobCategories" component={JobCategories} />
          <Route path="/location-history" component={LocationHistory} />
          <Route path="/company-data" component={CompanyData} />
          <Route path="/referral" component={Referral} />
          <Route path="/flyers" component={Flyers} />
          <Route path="/support" component={Support} />
          <Route path="/billing" component={Billing} />
          <Route path="/admin" component={Admin} />
          <Route path="/systemTags" component={SystemTags} />
          <Route path="/approval" component={Approval} />
          <Route path="/inactives" component={Inactives} />
          <Route path="/JobMarkets" component={JobMarkets} />
          <Route path="/job" exact component={Jobs} />
          <Route path="/personalised-notification" exact component={PersonalisedNotification} />
          <Route path="/app-data" exact component={AppData} />
          <Route path="/mapped-users" exact component={MappedUsers} />
          <Route path="/server-availability" exact component={ServerAvailability} />
          <Route path="/session" exact component={Session} />
          <Route path="/bucket" exact component={Bucket} />
          <Route path="/worker" component={Worker} />
          <Route path="/notification" exact component={Notifications} />
          <Route path="/call-details" exact component={CallDetails} />
          <Route path="/uninstalls" exact component={Uninstalls} />
          <Route path="/logs" exact component={Logs} />
          <Route path="/videos" exact component={Videos} />
          <Route path="/event-notification" exact component={EventNotification} />
        </SHCLayout>
      </AuthProvider>
    </Switch>
  </BrowserRouter>
);

// eslint-disable-next-line import/no-default-export
export default App;
