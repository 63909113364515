/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Avatar, Image, message, Form, Upload, Modal, Button, Input, Col, Tabs } from 'antd';
import { EditOutlined, DeleteOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';

import { request } from '../service/common';
import { DataTable } from './Table/Table';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import './Layout/style.css';
import { JobTitles } from './JobTitles';
import { Skills } from './Skills';
import { HCLayout } from './Layout/HCLayout';

const JobCategories = () => {
  const { TabPane } = Tabs;

  const [jobCategory, setJobCategory] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [labels, setLabels] = useState();
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    request('/api/jobCategories', 'GET')
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        message.success('Data loaded');
        setJobCategory(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const uploadCategories = (e) => {
    setLoading(true);
    form.resetFields();
    setIsModalVisible(false);
    request('/api/jobCategories', 'POST', {
      formData: {
        en: e.en,
        hi: e.hi,
        ta: e.ta,
        hin: e.hin,
        file: e.image.fileList[0].originFileObj,
      },
    }).then(async (res) => {
      console.log(res);
      setJobCategory([...jobCategory, res]);
      setLoading(false);
    });
  };

  const updateCategories = () => {
    setLoading(true);
    console.log(loading);
    request(`/api/jobCategories/${labels.id}`, 'PATCH', {
      formData: {
        en: labels.en,
        hi: labels.hi,
        ta: labels.ta,
        hin: labels.hin,
        file: labels.image,
      },
    })
      .then(async (res) => {
        setLoading(false);
        console.log(res);

        fetchData();
        setIsEditModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        message.error('Failed to upload');
      });
  };

  const deleteCategory = (item) => {
    Modal.confirm({
      title: 'Are you sure ?',
      content: 'This action cannot be un-done. are you sure you want to delete Category ?',
      okText: 'Yes, Delete',
      cancelText: 'Discard',
      onOk: () => {
        request(`/api/jobCategories/${item.id}`, 'DELETE')
          .then(() => {
            setJobCategory(jobCategory.filter((cat) => cat.id !== item.id));
            message.success('Job category deleted Successfuly');
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showEditModal = (item) => {
    item.label.id = item.id;
    setLabels(item.label);
    // setLabels({ ...labels, id: item.id });
    setIsEditModalVisible(true);
    setLoading(false);
  };

  const imageModal = (URL) => {
    setImageModalVisible(true);
    setPreviewImage(URL);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEditCancel = () => {
    setLabels(null);
    setIsEditModalVisible(false);
  };

  const imgModalCancel = () => {
    setImageModalVisible(false);
  };

  const categoryColumns = [
    {
      key: 'image',
      title: 'Image',
      render: (record) => (
        <Avatar
          size={64}
          onClick={() => {
            imageModal(record.imageUrl);
          }}
          src={<Image preview={false} src={record.imageUrl} />}
        />
      ),
    },
    {
      title: 'All Categories',
      key: 'name',
      render: (record) => record.label.en,
    },
    {
      title: 'In Hindi',
      key: 'hindi',
      render: (record) => record.label.hi,
    },
    {
      title: 'In Tamil',
      key: 'tamil',
      render: (record) => record.label.ta,
    },
    {
      title: 'In Hinglish',
      key: 'Hinglish',
      render: (record) => record.label.hin,
    },
    {
      key: 4,
      title: 'Action',
      render: (item) => (
        <>
          <EditOutlined
            title="Edit"
            style={innerTableActionBtnDesign}
            onClick={() => showEditModal(item)}
          />
          <DeleteOutlined
            title="Ban"
            onClick={() => deleteCategory(item)}
            style={innerTableActionBtnDesign}
          />
        </>
      ),
    },
  ];

  return (
    <HCLayout>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Job Categories" key="1">
          <Col>
            <b>Total Categories : {jobCategory.length} &nbsp; </b>

            <Button type="primary" onClick={showModal} icon={<PlusOutlined />} />
          </Col>
          <DataTable
            usersData={jobCategory}
            searchable={false}
            differRows
            pagination={false}
            loading={loading}
            columns={categoryColumns}
          />
        </TabPane>
        <TabPane tab="Jobs" key="2">
          <JobTitles />
        </TabPane>
        <TabPane tab="Skills" key="3">
          <Skills />
        </TabPane>
      </Tabs>

      {/* {console.log('label', labels)} */}
      <Modal
        footer=""
        title="Add New Job Category"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={700}
      >
        <Form layout="vertical" form={form} onFinish={uploadCategories}>
          <Form.Item
            name="en"
            label="Enter Category  In English"
            rules={[{ required: true, message: 'This filed is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="hi"
            label="Enter Category  In Hindi"
            rules={[{ required: true, message: 'This filed is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="ta"
            label="Enter Category  In Tamil"
            rules={[{ required: true, message: 'This filed is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="hin"
            label="Enter Category  In Hinglish"
            rules={[{ required: true, message: 'This filed is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="image"
            label="Upload Category Image"
            rules={[{ required: true, message: 'This filed is required' }]}
          >
            <Upload beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add New Category
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        footer=""
        title="Edit Category Type"
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        width={700}
      >
        {/* <Form layout="vertical" onFinish={updateCategories}> */}
        <Form.Item
          label="Enter Category In English"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="en"
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                en: e.target.value,
              }));
            }}
            value={labels?.en}
          />
        </Form.Item>

        <Form.Item
          label="Enter Category In Hindi"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="hi"
            value={labels?.hi}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                hi: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          label="Enter Category In Tamil"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="ta"
            value={labels?.ta}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                ta: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          label="Enter Category In Hinglish"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="hin"
            value={labels?.hin}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                hin: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          name="image"
          label="Upload Category Image"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                image: e?.fileList[0]?.originFileObj,
              }));
            }}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={() => updateCategories()} htmlType="submit">
            Update Job Category
          </Button>
        </Form.Item>
        {/* </Form> */}
      </Modal>

      <Modal footer="" title="Image Preview" visible={imageModalVisible} onCancel={imgModalCancel}>
        <Image preview={false} src={previewImage} />
      </Modal>
    </HCLayout>
  );
};

export { JobCategories };
