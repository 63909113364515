import React, { useEffect, useState } from 'react';
import { Collapse, Row } from 'antd';
import moment from 'moment';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';

const Logs = () => {
  const [loading, setLoading] = useState(true);

  const [logs, setLogs] = useState([]);

  const getLogs = () => {
    setLoading(true);
    request('/api/logs', 'GET').then((res) => {
      setLogs(res);
      setLoading(false);
    });
  };

  useEffect(() => getLogs(), []);
  const columns = [
    {
      key: '1',
      title: 'Title',
      render: (record) => record.title,
    },
    {
      key: '2',
      title: 'Logs',
      render: (record) => record.log,
    },
    {
      key: '3',
      title: 'Time Stamp',
      render: (record) => record.timestamp,
    },
  ];

  return (
    <HCLayout title="Logs">
      {logs.map((log) => (
        <Collapse>
          <Collapse.Panel
            header={log.title}
            extra={
              <>
                {new Date(`${log.timestamp}`).toLocaleDateString()} -
                {new Date(`${log.timestamp}`).toLocaleTimeString()}
              </>
            }
          >
            <div>{log.log}</div>
          </Collapse.Panel>
        </Collapse>
      ))}
    </HCLayout>
  );
};

export { Logs };
