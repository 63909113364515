/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */

import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Button, Col, Row, message } from 'antd';
import { HCLayout } from './Layout/HCLayout';
import { SessionData } from './SessionData';
import { FilterData } from './FilterData';
import { SearchData } from './SearchData';

const Session = () => {

  const actionBtn = [
    <Row gutter={16} key="1">
      <Col>
        <Link to="/export/sessions" target="_blank">
          <Button
            className="w-44"
            type="primary"
            style={{ border: 'none' }}
            onClick={() => {
              message.success('The file is downloading');
            }}
          >
            Export to CSV
            {/* <CSV
            filename="Sessions"
            data={allSessions.map((session) => {
              const updatedSession = { ...session };

              // updatedSession.btnClick = btnData.stringify;
              updatedSession.StatTime = new Date(updatedSession.createdAt).toLocaleString();
              updatedSession.duration = `${
                updatedSession.endTime
                  ? Math.floor(
                      (moment(updatedSession.endTime).diff(
                        moment(new Date(updatedSession.createdAt).toISOString()),
                      ) %
                        60000) /
                        10000,
                    )
                  : 0
              } Min`;
              updatedSession.EndTime = updatedSession.endTime
                ? new Date(updatedSession.endTime).toLocaleString()
                : 'N/A';
              updatedSession.buttonClicks
                ? Object.entries(JSON.parse(updatedSession.buttonClicks)).map((btnClick) => {
                    if (btnClick[1] !== 0) {
                      // eslint-disable-next-line prefer-destructuring
                      updatedSession[btnClick[0]] = btnClick[1];
                    }
                  })
                : null;
              delete updatedSession.createdAt;
              delete updatedSession.endTime;
              delete updatedSession.buttonClicks;
              return updatedSession;
            })}
          /> */}
          </Button>
        </Link>
      </Col>
    </Row>,
  ];

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Sessions"
      actions={actionBtn}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane key={1} tab="Sessions">
          <SessionData />
        </Tabs.TabPane>
        <Tabs.TabPane key={2} tab="Filter Data">
          <FilterData />
        </Tabs.TabPane>
        <Tabs.TabPane key={3} tab="Search Data">
          <SearchData />
        </Tabs.TabPane>
      </Tabs>
    </HCLayout>
  );
};

export { Session };
