/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';

const LocationHistory = () => {
  const [locationHistory, setLocationHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);
  const [page, setPage] = useState(1);
  // const [filters, setFilters] = useState({});

  const getLocationData = () => {
    setLoading(true);
    request(`/api/location-history?page=${page}`, 'GET')
      .then(async (res) => {
        if (res.length > 0) {
          res.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          res.map((item) => {
            item.key = item._id;
          });

          setLoading(false);
          setLocationHistory(res);
          // setSession(data);
        } else {
          if (page === 1) setSession(data);
          setNextBtnDisabled(true);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  useEffect(() => {
    getLocationData();
    // refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
    return () => {
      setLocationHistory();
    };
  }, [page]);

  useEffect(() => {
    getLocationData();
    return () => {
      setLocationHistory();
    };
  }, []);

  const cols = [
    {
      key: 1,
      title: 'User Id',
      render: (record) => record.userId,
    },
    {
      key: 2,
      title: 'Location',
      render: (record) => record.location,
    },
    {
      key: 3,
      title: 'Latitude',
      render: (record) => record.latitude,
    },
    {
      key: 4,
      title: 'Longitude',
      render: (record) => record.longitude,
    },
    {
      key: 5,
      title: 'Changed On',
      render: (record) => record.createdAt,
    },
  ];

  const actionBtn = [
    <Row>
      <Col>
        <Link to="/export/location-history" target="_blank">
          <Button
            type="primary"
            className="w-44"
            style={{ border: 'none' }}
            onClick={() => {
              message.success('The file is downloading');
            }}
          >
            Export to CSV
          </Button>
        </Link>
      </Col>
    </Row>,
  ];
  return (
    <HCLayout title="Location History" actions={actionBtn}>
      <DataTable columns={cols} loading={loading} pagination={false} usersData={locationHistory} />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
    </HCLayout>
  );
};

export { LocationHistory };
