/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */

import React, { useState, useEffect } from 'react';
import {
  Upload,
  Button,
  Row,
  Image,
  Divider,
  Modal,
  message,
  Avatar,
  Tooltip,
  Select,
  Switch,
} from 'antd';
import { DeleteOutlined, InboxOutlined, LinkOutlined, ReloadOutlined } from '@ant-design/icons';

import imageCompression from 'browser-image-compression';

import { HCLayout } from './Layout/HCLayout';

import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';

import { request } from '../service/common';

import { DataTable } from './Table/Table';

const { Option } = Select;

const { Dragger } = Upload;

// const style = { padding: '8px 0' };

const Flyers = () => {
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(1);
  const [stakeholder, setStakeholder] = useState(0);
  const [clickable, setClickable] = useState(false);
  const [flyers, setFlyers] = useState([]);
  const [files, setFiles] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const getFlyers = () => {
    setLoading(true);
    request('/api/flyer/all', 'GET')
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        setFlyers(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getFlyers();
    return () => {
      setFlyers();
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (files.length < 1) {
      setLoading(false);
      message.warn('Please select any file');
    } else {
      setLoading(true);
      files.forEach(async (fylerImage) => {
        const compressedImage = await imageCompression(fylerImage.originFileObj, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });

        const file = new File([compressedImage], fylerImage.name, {
          lastModified: new Date(),
          type: fylerImage.type,
        });

        console.log(fylerImage.name);
        console.log(fylerImage.originFileObj);
        console.log(file);

        request('/api/flyer', 'POST', {
          formData: {
            image: file,
            language,
            stakeholder,
            clickable,
          },
        })
          .then((res) => {
            console.log(res);
            setFiles([]);
            setFlyers([res, ...flyers]);
          })
          .catch((err) => console.log('err', err))
          .finally(() => setLoading(false));
      });
    }
  };

  const onClickableChange = (e, id) => {
    setLoading(true);

    request(`/api/flyer/update/${id}`, 'PATCH', { data: { clickable: e } })
      .then((res) => {
        console.log(res);
        setFlyers(flyers.map((flyer) => (flyer.id === id ? { ...flyer, clickable: e } : flyer)));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const uploaderProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        setFiles(info.fileList);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to delete',
      okText: 'Yes, Delete',
      onOk: () => {
        setLoading(true);
        request(`/api/flyer/${record.id}`, 'DELETE')
          .then(async () => {
            setFlyers(flyers.filter((flyer) => record.id !== flyer.id));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          });
      },
    });
  };
  // const fileSelectedHandler = (e) => {
  //   setFiles(e.target.files);
  // };
  const columns = [
    {
      key: 'image',
      title: 'Image',
      render: (record) => (
        <Avatar
          size={64}
          onClick={() => {
            setPreviewImage(record.imageUrl);
            setPreviewModal(true);
          }}
          src={<Image preview={false} src={record.imageUrl} />}
        />
      ),
    },
    {
      key: 'language',
      title: 'Language',
      render: (record) =>
        record.language === 1
          ? 'English'
          : record.language === 2
          ? 'Hindi'
          : record.language === 3
          ? 'Tamil'
          : record.language === 4
          ? 'Hinglish'
          : 'N/A',
    },
    {
      key: 'stakeholder',
      title: 'Visible to',
      render: (record) =>
        record.stakeholder === 1
          ? 'Labour'
          : record.stakeholder === 2
          ? 'MiddleMan'
          : record.stakeholder === 3
          ? 'MillOwner'
          : record.stakeholder === 4
          ? 'Worker'
          : 'CRM Only',
    },
    {
      key: 'link',
      title: 'Link',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.link}>
          <a target="_blank" rel="noreferrer" href={record.link}>
            {' '}
            <LinkOutlined style={innerTableActionBtnDesign} /> {record.link}{' '}
          </a>
        </Tooltip>
      ),
    },
    {
      key: 'clickable',
      title: 'Clickable',
      render: (record) => (
        <Switch
          defaultChecked={record.clickable}
          onChange={(e) => onClickableChange(e, record.id)}
        />
      ),
    },
    {
      key: 'createdAt',
      title: 'Uploaded on',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.createdAt).toLocaleDateString()} ${new Date(
            record.createdAt,
          ).toLocaleTimeString()}`}
        >
          {`${new Date(record.createdAt).toLocaleDateString()}`}
        </Tooltip>
      ),
    },
    {
      key: 'delete',
      title: 'Action',
      render: (record) => (
        <DeleteOutlined style={innerTableActionBtnDesign} onClick={() => onDelete(record)} />
      ),
    },
  ];
  const actionBtn = [
    <Button key="1" type="primary" onClick={getFlyers}>
      <ReloadOutlined />
    </Button>,
  ];
  const languageFilters = [];
  languageFilters.push(<Option key="1">English</Option>);
  languageFilters.push(<Option key="2">Hindi</Option>);
  languageFilters.push(<Option key="3">Tamil</Option>);
  languageFilters.push(<Option key="4">Hinglish</Option>);
  const stakeholderFilters = [];
  stakeholderFilters.push(<Option key="1">Labour</Option>);
  stakeholderFilters.push(<Option key="2">Middleman</Option>);
  stakeholderFilters.push(<Option key="3">Mill Owner</Option>);
  stakeholderFilters.push(<Option key="4">Worker</Option>);
  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Flyers"
      actions={actionBtn}
    >
      Language
      <Select
        style={{ zIndex: 99, width: '350px', marginLeft: '34px' }}
        allowClear
        className="z-50"
        onChange={(e) => setLanguage(e)}
      >
        {languageFilters}
      </Select>
      <br />
      Stakeholder
      <Select
        style={{ zIndex: 99, width: '350px', marginLeft: '20px' }}
        allowClear
        className="z-50"
        onChange={(e) => setStakeholder(e)}
      >
        {stakeholderFilters}
      </Select>
      <br />
      Clickable
      <Switch
        defaultChecked={clickable}
        style={{ zIndex: 99, marginLeft: '40px' }}
        className="z-50"
        onChange={(e) => setClickable(e)}
      />
      <Dragger {...uploaderProps} style={{ height: '150px !important' }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag flyers to this area to upload</p>
      </Dragger>
      <Button onClick={handleSubmit}>Add Flyer</Button>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <DataTable usersData={flyers} loading={loading} pagination={false} columns={columns} />
      </Row>
      <Modal
        title="Flyer Preview"
        visible={previewModal}
        onCancel={() => {
          setPreviewImage('');
          setPreviewModal(false);
        }}
      >
        <Image preview={false} src={previewImage} />
      </Modal>
    </HCLayout>
  );
};

export { Flyers };
