/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Tooltip, Form, Modal, Select, Upload, Switch } from 'antd';
import { DownloadOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';
import { request } from '../service/common';
import './Layout/style.css';

const Billing = () => {
  const { Option } = Select;
  const [invoices, setInvoices] = useState();
  const [canShowInactive, setCanShowInactive] = useState(false);
  const [loading, setLoading] = useState(true);

  const [invoiceModal, setInvoiceModal] = useState(false);

  const [allMillList, setAllMillList] = useState([]);

  const [invoiceFileModal, setInvoiceFileModal] = useState(false);

  const [invoiceFile, setInvoiceFile] = useState(null);

  const [currentInvoiceId, setCurrentInvoiceId] = useState(null);
  const [currentMillId, setCurrentMillId] = useState(null);

  const requestsCaller = (val) => {
    setLoading(true);
    setCanShowInactive(val);
    val
      ? request(`/api/invoice/getAllInvoices/`, 'GET')
          .then(async (data) => {
            console.log(data);
            setInvoices(data.filter((inv) => inv.status === 2));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(`err ${err}`);
            throw err;
          })
      : request(`/api/invoice/getAllInvoices/`, 'GET')
          .then(async (data) => {
            // console.log(data);

            setInvoices(data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(`err ${err}`);
            throw err;
          });
  };

  // const showInactive = (val) => {
  //   request(`/api/invoice/getAllInvoices/`, 'GET')
  //     .then(async (data) => {
  //       console.log(data);
  //       setInvoices(data.filter((inv) => inv.status === 2));
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(`err ${err}`);
  //       throw err;
  //     });
  //   setCanShowInactive(!canShowInactive);
  // };

  const getAllFactoryData = () => {
    request('/api/getAll/mills', 'GET')
      .then((data) =>
        setAllMillList(
          data.sort((a, b) => {
            const millNameA = a.millName.toUpperCase();
            const millNameB = b.millName.toUpperCase();
            return millNameA < millNameB ? -1 : millNameA > millNameB ? 1 : 0;
          }),
        ),
      )
      .catch((error) => console.log(error));
  };

  const newInvoiceModal = () => {
    setInvoiceModal(true);
  };

  const onInvoiceCancel = () => {
    setInvoiceModal(false);
  };

  const generateNewInvoice = (e) => {
    setLoading(true);
    const millId = e.mill;

    delete e.mill;
    e.status = 0;
    e.amount = parseFloat(e.amount);
    e.type = parseInt(e.type, 10);
    e.dueDate = new Date(e.dueDate);

    request(`/api/invoice/invoice-detail/${millId}`, 'POST', {
      data: e,
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setInvoices([...invoices, res.data]);
        setInvoiceModal(false);
      })
      .catch((err) => console.log(err));
  };

  const setInvoice = (invoiceID, millId) => {
    setInvoiceFileModal(true);
    setCurrentInvoiceId(invoiceID);
    setCurrentMillId(millId);
  };

  const markPaid = (data, status) => {
    setLoading(true);
    request(`/api/invoice/updateStatus/${data.id}/${data.millId}`, 'PATCH', {
      data: { status },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleInvoice = (e) => {
    console.log(e);
    setInvoiceFile(e.fileList[0]);
  };

  const uploadInvoice = () => {
    setLoading(true);
    console.log({ pdf: invoiceFile.originFileObj });
    request(`/api/invoice/uploadInvoice/${currentInvoiceId}/${currentMillId}`, 'PATCH', {
      formData: { pdf: invoiceFile.originFileObj },
    })
      .then(() => {
        setLoading(false);
        setCurrentInvoiceId(null);
        setCurrentMillId(null);
        setInvoiceFile(null);
        setInvoiceFileModal(false);
        setInvoices([]);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleInvoiceCancel = () => {
    setCurrentInvoiceId(null);
    setCurrentMillId(null);
    setInvoiceFile(null);
    setInvoiceFileModal(false);
  };

  useEffect(() => {
    getAllFactoryData();
    requestsCaller();
    return () => {
      setAllMillList();
      setInvoices();
    };
  }, []);

  const columns = [
    {
      key: 'Mill',
      title: 'Mill Name',
      render: (record) => (
        <Tooltip title={record?.mill?.millName}>{record?.mill?.millName}</Tooltip>
      ),
    },
    {
      key: 'Date',
      title: 'Date',
      render: (record) => (
        <Tooltip
          title={`${new Date(record.createdAt).toLocaleDateString()} - ${new Date(
            record.createdAt,
          ).toLocaleTimeString()}`}
        >
          {new Date(record.createdAt).toLocaleDateString()}
        </Tooltip>
      ),
    },

    {
      key: 'InvoiceNo',
      title: 'Invoice No.',
      render: (record) => record.invoiceNumber,
    },
    {
      key: 'Type',
      title: 'Type',
      render: (record) => (record.type === 1 ? 'Travel' : record.type === 2 ? 'Commission' : ''),
    },

    {
      key: 'Desc',
      title: 'Description',
      render: (record) => record.description,
      // filters: [
      //   { text: 'Male', value: 'Male' },
      //   { text: 'Female', value: 'Female' },
      // ],
      // onFilter: (value, record) => (value === 'Male' ? record.gender === 1 : record.gender === 2),
    },

    {
      key: 'Amount',
      title: 'Amount',
      render: (record) => record.amount,
    },

    {
      key: 'dueDate',
      title: 'Due Date',
      render: (record) => new Date(record.dueDate).toLocaleDateString(),
    },

    {
      key: 'status',
      title: 'Status',
      render: (record) => (record.status === 0 ? 'Pending' : 'Paid'),
    },
    {
      key: 'invoice',
      title: 'Invoice',
      render: (record) =>
        record.invoice ? (
          <Button
            type="primary"
            style={{ background: '#04AA6D', border: 0 }}
            icon={<DownloadOutlined />}
            onClick={() => saveAs(record.invoice)}
          >
            Download
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={() => setInvoice(record.id, record.millId)}
          >
            Upload
          </Button>
        ),
    },
    {
      key: 'Paid',
      title: 'Pay',
      render: (record) =>
        record.status === 0 ? (
          <Button type="primary" onClick={() => markPaid(record, 1)}>
            Mark Paid
          </Button>
        ) : (
          <Button type="primary" onClick={() => markPaid(record, 0)}>
            Mark Unpaid
          </Button>
        ),
    },
    {
      title: 'Inactive',
      key: 'inactive',
      render: (record) => (
        <Button type="primary" onClick={() => markPaid(record, 2)}>
          Set Inactive
        </Button>
      ),
    },
  ];

  const actionBtn = [
    <Row gutter={[24]}>
      <Col>
        <div key="1" style={{ paddingTop: 5 }}>
          Trash: &nbsp;
          <Switch defaultChecked={canShowInactive} onChange={requestsCaller} />
        </div>
      </Col>
      <Col>
        <Button type="primary" key="2" onClick={() => newInvoiceModal()}>
          Generate New Invoice
        </Button>
      </Col>
    </Row>,
  ];

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Billing & Invoice"
      actions={actionBtn}
    >
      <DataTable
        usersData={invoices}
        searchable={false}
        differUserRows
        pagination={false}
        loading={loading}
        columns={columns}
      />

      <Modal
        visible={invoiceModal}
        title="Generate New Invoice"
        onCancel={() => onInvoiceCancel()}
        footer={[]}
      >
        <Form layout="vertical" onFinish={(e) => generateNewInvoice(e)} required>
          <Form.Item
            label="Select Factory"
            name="mill"
            rules={[{ required: true, message: 'Please select a mill' }]}
          >
            <Select>
              {allMillList.map((mill) => (
                <Option key={mill.id} value={mill.id}>
                  {mill.millName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Invoice Number"
            rules={[{ required: true, message: 'Please enter invoice number' }]}
            name="invoiceNumber"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Type"
            required
            name="type"
            rules={[{ required: true, message: 'Please select a Type' }]}
          >
            <Select>
              <Option value="1">Travel</Option>
              <Option value="2">Commission</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Amount"
            required
            name="amount"
            rules={[{ required: true, message: 'Please enter an amount' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Due Date"
            required
            name="dueDate"
            rules={[{ required: true, message: 'Please select a due date' }]}
          >
            <input type="date" style={{ width: '100%', border: '1px solid #d9d9d9', padding: 2 }} />
          </Form.Item>
          <Form.Item
            label="Description"
            required
            name="description"
            rules={[{ required: true, message: 'Please add description' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Generate
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal visible={invoiceFileModal} footer={[]} onCancel={() => handleInvoiceCancel()}>
        <Row>
          <Col span={24} style={{ paddingTop: 30 }}>
            <Upload
              onChange={(e) => handleInvoice(e)}
              style={{ width: '100%', border: '1px solid black' }}
              beforeUpload={() => false}
              listType="picture"
            >
              <center>
                <InboxOutlined style={{ fontSize: 40 }} />
                <p>Click here to upload</p>
              </center>
            </Upload>
          </Col>
        </Row>
        <center>
          <br />
          <Button style={{ width: '100%' }} type="primary" onClick={() => uploadInvoice()}>
            Upload Invoice
          </Button>
        </center>
      </Modal>
    </HCLayout>
  );
};

export { Billing };
