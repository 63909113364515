/* eslint-disable no-lonely-if */
/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, message, Modal, Row, Select, Tabs, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import QS from 'query-string';
import React, { useState, useEffect, useContext } from 'react';
import { request } from '../service/common';
import { Desc } from './Common/Description';
import { HCLayout } from './Layout/HCLayout';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';

import { DataTable } from './Table/Table';
import { AuthContext } from '../context/Authcontext';

const { Option } = Select;

const { TabPane } = Tabs;

const PartnerMills = () => {
  const { search } = useLocation();

  const userId = search.split('=')[1];

  const [loading, setLoading] = useState(false);

  const [partnerMills, setPartnerMills] = useState([]);

  const { userContext } = useContext(AuthContext);

  const [allPartnerMills, setAllPartnerMills] = useState([]);

  const [industryTypes, setIndustryTypes] = useState([]);
  const [editModalVisiblity, setEditModalVisiblity] = useState(false);
  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);
  const [siderProps, setSiderProps] = useState({});

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [filters, setFilters] = useState({});

  const [drawer, setDrawer] = useState(false);
  const [editMillData, setEditMillData] = useState({});
  const [page, setPage] = useState(1);

  // const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  // const [prevBtnDisable, setPrevBtnDisabled] = useState(false);

  const getPartnerFactories = () => {
    setLoading(true);

    const url = userId
      ? `/api/mill/getPartnerMillsForCrm?isCrm=1&ownerId=${userId}`
      : `/api/mill/getPartnerMillsForCrm?isCrm=1`;

    request(url, 'GET')
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            item.key = item.id;
          });

          setPartnerMills(data);
        } else {
          if (page === 1) {
            setPartnerMills(data);
          }
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const refreshTable = (queryString = '') => {
    setLoading(true);

    console.log(queryString);

    const url = userId
      ? `/api/mill/getPartnerMillsForCrm?isCrm=1&ownerId=${userId}&${queryString}`
      : `/api/mill/getPartnerMillsForCrm?isCrm=1&${queryString}`;

    request(url, 'GET')
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            item.key = item.id;
          });

          setPartnerMills(data);
        } else {
          if (page === 1) {
            setPartnerMills(data);
          }
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
    return () => {
      setIsFilterChanged();
    };
  }, [isFilterChanged]);

  const onTableFilterChange = (query) => {
    setFilters({ ...filters, ...query });
  };

  const clearFilter = (type) => {
    setPage(1);
    setFilters({ ...filters, [type]: undefined });
    setIsFilterChanged(true);
  };

  const setFilterChange = () => {
    setIsFilterChanged(true);
    // setFilters({ ...filters, isChanged: true });
  };

  const getAllIndustries = () => {
    request('/api/admin-tasks/dropdown/industryType')
      .then(async (data) => {
        setIndustryTypes(data);
      })
      .catch((err) => {
        message.error('Failed to load data');
        throw err;
      });
  };

  const getAllPartnerMills = () => {
    request('/api/mill/partner-mills?isCrm=1', 'GET')
      .then(async (data) => {
        setAllPartnerMills(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllIndustries();
    getPartnerFactories();
    getAllPartnerMills();
    return () => {
      setIndustryTypes();
      setPartnerMills();
      setAllPartnerMills();
    };
  }, []);

  const pageNext = () => {
    setFilters({ ...filters, page: page + 1 });
    setPage(page + 1);
    setFilterChange(true);
  };
  const pagePrev = () => {
    if (page > 0) {
      setFilters({ ...filters, page: page - 1 });
      setFilterChange(true);
    }
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  useEffect(() => {
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
    return () => {
      setPartnerMills();
    };
  }, [page]);

  const onEdit = (record) => {
    setEditModalVisiblity(true);
    setEditMillData(record);
  };

  const onDrawerClose = () => {
    setSiderProps({});
    setDrawer(false);
  };

  const onDrawerOpen = (record) => {
    setSiderProps({
      title: record.millName,
      data: record,
    });
    setDrawer(true);
  };

  const data = siderProps.data || {};
  const onEditModalClose = () => {
    setEditModalVisiblity(false);
    setEditMillData({});
  };

  const editModalSave = () => {
    setLoading(true);
    setEditModalVisiblity(false);
    request(`/api/mill?millId=${editMillData.id}`, 'PATCH', {
      data: {
        millName: editMillData.millName,
        address: editMillData.address,
        area: editMillData.area,
        city: editMillData.city,
        state: editMillData.state,
        pinCode: editMillData.pinCode,
        industryType: editMillData.industryType,
        millHr: editMillData.millHr,
        hrNumber: editMillData.hrNumber,
      },
    })
      .then(async () => {
        setPartnerMills(
          partnerMills.map((factory) => (factory.id === editMillData.id ? editMillData : factory)),
        );
        setEditMillData({});
        setLoading(false);
        message.success('Mill Updated');
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  const onDelete = (id, userId) => {
    setLoading(true);
    Modal.confirm({
      title: 'Are you sure, you want to Ban mill',
      okText: 'Yes, Ban',
      onOk: () => {
        request(`/api/mill?millId=${id}&userId=${userId}`, 'DELETE')
          .then(() => {
            setLoading(false);
            setPartnerMills(partnerMills.filter((mill) => mill.id !== id));
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          });
      },
    });
  };

  const actionBtn = [
    <Row gutter={16} key="1">
      <Col>
        <Button
          type="primary"
          onClick={() => {
            setPage(1);
            requestsCaller();
          }}
        >
          <ReloadOutlined />
        </Button>
      </Col>
      <Col>
        {userContext.access['download'][0] ? (
          <Link to="/export/partner-mills" target="_blank">
            <Button
              type="primary"
              className="w-44"
              style={{ border: 'none' }}
              onClick={() => {
                message.success('The file is downloading');
              }}
            >
              Export to CSV
            </Button>
          </Link>
        ) : null}
      </Col>
    </Row>,
  ];

  const cols = [
    {
      key: '1',
      title: 'Mill Name',
      dataIndex: 'millName',
      ellipsis: {
        showTitle: false,
      },
      render: (millName) => (
        <Tooltip placement="topLeft" title={millName}>
          {millName}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.millName}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  millName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('millName')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '0',
      title: 'HR Name',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip placement="topLeft" title={record.millHr}>
          {record.millHr}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.millHr}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  millHr: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('millHr')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '2',
      title: 'HR Phone',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip placement="topLeft" title={record.hrNumber}>
          {record.hrNumber}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.hrNumber}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  hrNumber: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('hrNumber')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '3',
      title: 'Cont. Name',

      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip placement="topLeft" title={record.millOwner.userInfo.name}>
          {record.millOwner.userInfo.name}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.name}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  name: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('name')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '4',
      title: 'Cont. Number',

      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip placement="topLeft" title={record.millOwner.userInfo.phone}>
          {record.millOwner.userInfo.phone}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.phone}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  phone: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('phone')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '5',
      title: 'City',

      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip placement="topLeft" title={record.city}>
          {record.city}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.city}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  city: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('city')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '6',
      title: 'State',

      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip placement="topLeft" title={record.state}>
          {record.state}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.state}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  state: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('state')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: '7',
      title: 'Jobs',
      render: (record) => (
        <Link to={`/job?millId=${record.id}`}>
          <LinkOutlined style={innerTableActionBtnDesign} /> {record.jobs.length}
        </Link>
      ),
    },
    {
      key: '8',
      title: 'Applications',
      render: (record) =>
        record.jobs?.reduce((count, job) => count + (job.applications || []).length, 0),
    },
    {
      key: 'action',
      title: 'Action',
      width: 200,
      render: (record) => (
        <>
          <EyeOutlined
            style={innerTableActionBtnDesign}
            onClick={() => {
              onDrawerOpen(record);
            }}
          />
          <EditOutlined style={innerTableActionBtnDesign} onClick={() => onEdit(record)} />
          <DeleteOutlined
            style={innerTableActionBtnDesign}
            onClick={() => {
              onDelete(record.id, record.millOwner.userInfo.id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <HCLayout actions={actionBtn} title="Partner Mills">
      <DataTable columns={cols} usersData={partnerMills} pagination={false} loading={loading} />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
      <Drawer
        title={`${siderProps?.title}'s information`}
        width="750px"
        placement="right"
        onClose={onDrawerClose}
        visible={drawer}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Factory Information" key="1">
            <Row>
              <Col span={12} lg={12} md={12} sm={32} xs={32}>
                <Desc title="Mill Name" content={data.millName} />
                <Desc title="Address" content={data.address} />
                <Desc title="State" content={data.state} />
                <Desc title="Area" content={data.area} />
                <Desc title="City" content={data.city} />
                <Desc title="Industry Type" content={data.industryType} />
              </Col>
              <Col span={12} lg={12} md={12} sm={32} xs={32}>
                <Desc title="Owner Name" content={data.millOwner?.userInfo?.name} />
                <Desc title="Phone number" content={data.millOwner?.userInfo?.phone} />
                <Desc title="Pin Code" content={data.pinCode} />

                <Desc title="Latitude" content={data.latitude} />
                <Desc title="Longitude" content={data.longitude} />
                <Desc
                  title="Contractor"
                  content={data.millOwner?.userInfo?.isContractor ? 'Yes' : 'No'}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Drawer>

      <Modal
        title="Edit Mill"
        visible={editModalVisiblity}
        onCancel={onEditModalClose}
        onOk={editModalSave}
        okText="Update Mill Info"
      >
        <Form.Item label="Mill Name">
          <Input
            title="Mill Name "
            value={editMillData.millName}
            placeholder="Enter Mill Name"
            required
            onChange={(e) => {
              setEditMillData((editMillData) => ({
                ...editMillData,
                millName: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item label="Address">
          <Input
            title="Address "
            value={editMillData.address}
            placeholder="Enter Address"
            required
            onChange={(e) => {
              setEditMillData({ ...editMillData, address: e.target.value });
            }}
          />
        </Form.Item>

        <Form.Item label="Area">
          <Input
            title="Area "
            value={editMillData.area}
            onChange={(e) => {
              setEditMillData({ ...editMillData, area: e.target.value });
            }}
            placeholder="Enter Area"
            required
          />
        </Form.Item>

        <Form.Item label="City">
          <Input
            title="City "
            value={editMillData.city}
            onChange={(e) => {
              setEditMillData({ ...editMillData, city: e.target.value });
            }}
            placeholder="Enter City"
            required
          />
        </Form.Item>

        <Form.Item label="State">
          <Input
            title="State "
            value={editMillData.state}
            onChange={(e) => {
              setEditMillData({ ...editMillData, state: e.target.value });
            }}
            placeholder="Enter State"
            required
          />
        </Form.Item>

        <Form.Item label="Pin Code">
          <Input
            title="Pin Code "
            value={editMillData.pinCode}
            placeholder="Enter Pin Code"
            required
            onChange={(e) => {
              setEditMillData({ ...editMillData, pinCode: e.target.value });
            }}
          />
        </Form.Item>

        <Form.Item label="Industry Type">
          <Select
            value={editMillData.industryType}
            onChange={(value) => {
              setEditMillData({ ...editMillData, industryType: value });
            }}
          >
            {industryTypes.map((type) => (
              <Option value={type.label} key={type.id}>
                {type.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="HR Name">
          <Input
            title="HR Name "
            value={editMillData.millHr}
            placeholder="Enter HR Name"
            required
            onChange={(e) => {
              setEditMillData({ ...editMillData, millHr: e.target.value });
            }}
          />
        </Form.Item>

        <Form.Item label="HR Number">
          <Input
            title="HR Number "
            value={editMillData.hrNumber}
            placeholder="Enter HR Number"
            required
            onChange={(e) => {
              setEditMillData({ ...editMillData, hrNumber: e.target.value });
            }}
          />
        </Form.Item>
      </Modal>
    </HCLayout>
  );
};

export { PartnerMills };
