/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Tabs, Image, Row, Col, Modal, Button, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { HCLayout } from './Layout/HCLayout';
import { request } from '../service/common';
import { IndustryTypes } from './industryTypes';
import { Skills } from './Skills';
import { JobTitles } from './JobTitles';
import './Layout/style.css';

const { TabPane } = Tabs;

const MillTypes = () => {
  const [skillImageModalVisible, setSkillImageModalVisible] = useState(false);

  const fetchData = () => {
    // setLoading(true);
    request('/v2/api/admin-tasks/dropdown/industryType')
      .then(async () => {
        message.success('Data loaded');
        // setIndustryTypes(data);
        // setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const skillImgModalCancel = () => {
    setSkillImageModalVisible(false);
  };

  const actionBtn = [
    <Row key="1">
      <Col>
        <Button type="primary" onClick={fetchData}>
          <ReloadOutlined />
        </Button>
        ,
      </Col>
    </Row>,
  ];

  return (
    <HCLayout
      title="Industry Types"
      onBack={() => {
        window.location.href = '/';
      }}
      actions={actionBtn}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Industry" key="1">
          <IndustryTypes />
        </TabPane>
        <TabPane tab="Jobs" key="2">
          <JobTitles />
        </TabPane>
        <TabPane tab="Skills" key="3">
          <Skills />
        </TabPane>
      </Tabs>

      <Modal
        footer=""
        title="Image Preview"
        visible={skillImageModalVisible}
        onCancel={skillImgModalCancel}
      >
        <Image preview={false} src="https://joeschmoe.io/api/v1/random" />
      </Modal>
    </HCLayout>
  );
};

export { MillTypes };
