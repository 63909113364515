/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form, Input, message } from 'antd';
import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';

import { DataTable } from './Table/Table';
import { request } from '../service/common';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';

import { HCLayout } from './Layout/HCLayout';
import './Layout/style.css';

const JobMarkets = () => {
  const [loading, setLoading] = useState(true);
  const [jobMarkets, setJobMarkets] = useState([]);
  const [modal, setModal] = useState(false);

  const requestsCaller = () => {
    request('/api/job-markets', 'GET')
    .then(async (res) => {
      // eslint-disable-next-line array-callback-return
      res.map((item) => {
        item.key = item.id;
      });
      setLoading(false);
      setJobMarkets(res);
    });
  };

  // console.log(res)

  useEffect(() => {
    requestsCaller();
    return () => {
      setJobMarkets();
    }
  }, []);

  const addNewJobMarket = (values) => {
    values.lat = parseFloat(values.lat);
    values.long = parseFloat(values.long);

    console.log(values);

    request('/api/job-markets', 'POST', {
      data: values,
    })
      .then((res) => {
        console.log(res);
        setModal(false);
        setJobMarkets([...jobMarkets, res]);
      })
      .catch((err) => console.log(err));
  };

  const deleteMarket = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to delete ?',
      okText: 'Yes, Delete',
      cancelText: 'Abort',
      onOk: () => {
        request(`/api/job-markets/${record.id}`, 'DELETE')
          .then(() => {
            setJobMarkets(jobMarkets.filter((jMarket) => jMarket.id !== record.id));
            message.success('Job Market Deleted Successfuly');
          })
          .then((err) => console.log(err));
      },
    });
  };

  const columns = [
    {
      key: 'City',
      title: 'City',
      dataIndex: 'city',
    },
    {
      key: 'lat',
      title: 'Latitude',
      dataIndex: 'lat',
    },
    {
      key: 'long',
      title: 'Longitude',
      dataIndex: 'long',
    },

    {
      key: 'action',
      title: 'Action',
      width: 200,
      render: (record) => (
        <>
          {/* <EditOutlined style={innerTableActionBtnDesign} onClick={() => onEdit(record)} /> */}

          <DeleteOutlined
            title="Delete Permanently"
            style={innerTableActionBtnDesign}
            onClick={() => deleteMarket(record)}
          />
        </>
      ),
    },
  ];

  const actionBtn = [
    <Row gutter={16} key="1">
      <Col>
        <Button type="primary" onClick={() => setModal(true)}>
          Add New Job Market
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() => {
            requestsCaller();
          }}
        >
          <ReloadOutlined />
        </Button>
      </Col>
    </Row>,
  ];
  // eslint-disable-next-line react/jsx-filename-extension
  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Popular Job Markets"
      actions={actionBtn}
    >
      <DataTable
        usersData={jobMarkets}
        loading={loading}
        pagination={false}
        differRows
        columns={columns}
      />

      <Modal
        footer=""
        visible={modal}
        title="Add Popular Job Market"
        onCancel={() => setModal(false)}
      >
        <Form layout="vertical" onFinish={addNewJobMarket}>
          <Form.Item rules={[{ required: true, message: 'Enter City' }]} name="city" label="City">
            <Input />
          </Form.Item>
          <Form.Item
            name="lat"
            label="Latitude"
            rules={[{ required: true, message: 'Enter Latitude' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="long"
            label="Longitude"
            rules={[{ required: true, message: 'Enter Longitude' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add New Job Market
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </HCLayout>
  );
};

export { JobMarkets };