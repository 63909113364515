/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';

import { request } from '../service/common';
import { DataTable } from './Table/Table';

export const SearchData = () => {
  const [searches, setSearches] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearches = () => {
    setLoading(true);
    request(`/api/searchString`, 'GET')
      .then(async (res) => {
        // eslint-disable-next-line array-callback-return
        res.map((item) => {
          item.key = item._id;
        });
        setSearches(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => getSearches(), []);

  const cols = [
    {
      key: 1,
      title: 'UserId',
      render: (record) => record.userId,
    },
    {
      key: 2,
      title: 'Search Strings',
      render: (record) => record.searchString,
    },
    {
      key: 3,
      title: 'Date',
      render: (record) => (
        <Tooltip placement="top" title={`${new Date(record.createdAt).toISOString()}`}>
          {`${new Date(record.createdAt).toLocaleString()}`}
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <DataTable columns={cols} usersData={searches} loading={loading} />
    </>
  );
};
