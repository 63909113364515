/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Avatar, Image, message, Button, Modal, Row, Col, Form, Input, Select, Upload } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  MinusCircleFilled,
  UploadOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { request } from '../service/common';
import { DataTable } from './Table/Table';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import './Layout/style.css';

const { Option } = Select;

const Skills = () => {
  // const [skillImageModalVisible, setSkillImageModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [labels, setLabels] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jobCategories, setJobCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSkills, setCurrentSkills] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState([]);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const skillImageModal = () => {
    // setSkillImageModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchData();
    getIndustries();
  }, []);

  const fetchData = () => {
    setLoading(true);
    request('/api/industryTypes/skills')
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        message.success('Data loaded');
        setSkills(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const getIndustries = () => {
    setLoading(true);
    request('/api/jobCategories')
      .then(async (data) => {
        message.success('Data loaded');
        // console.log(data);
        setJobCategories(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const handleSkills = (e) => {
    // console.log(e);
    if(selectedJobCategory.length === 0){
      message.error('Please select Job Category');
      return
    }

    setCurrentSkills([
      ...currentSkills,
      {
        label: { en: e.en, hi: e.hi, ta: e.ta, hin: e.hin },
        categoryId: selectedJobCategory[1],
        // categoryId: e.jobCategory[1],
        // industryLabel: e.jobCategory[0],
        industryLabel: selectedJobCategory[0],
        image: e.image.fileList[0].originFileObj,
      },
    ]);
    form.resetFields();
  };

  // useEffect(() => {
  //   console.log(currentSkills);
  // }, [currentSkills]);

  const handleCurrentSkills = (label) => {
    setCurrentSkills(currentSkills.filter((item) => item.label.en !== label));
  };

  const uploadSkills = () => {
    setLoading(true);
    setIsModalVisible(false);
    currentSkills.map((skill) => {
      // console.log('data', skill);
      request('/api/industryTypes/create/skills', 'POST', {
        formData: {
          en: skill.label.en,
          hi: skill.label.hi,
          ta: skill.label.ta,
          hin: skill.label.hin,
          categoryId: skill.categoryId,
          image: skill.image,
        },
      }).then(async (res) => {
        // console.log('response', res);
        setJobCategories([...jobCategories, res]);
        setLoading(false);
      });
    });
  };

  const updateSkills = () => {
    setLoading(true);
    // console.log(loading);
    request(`/api/industryTypes/update/skills/${labels.id}`, 'PATCH', {
      formData: {
        en: labels.en,
        hi: labels.hi,
        ta: labels.ta,
        hin: labels.hin,
        file: labels.image,
      },
    })
      .then(async (res) => {
        setLoading(false);
        console.log(res);
        setTimeout(fetchData, 2000);

        setIsEditModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        message.error('Failed to upload');
      });
  };

  const filterSkills = (e) => {
    console.log(e);
    setLoading(true);
    request(`/api/industryTypes/skills?id=${e}`)
      .then(async (data) => {
        message.success('Data loaded');
        // console.log(data);
        setSkills(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const deleteSkill = (item) => {
    Modal.confirm({
      title: 'Are you sure ?',
      content: 'This action cannot be un-done. are you sure you want to delete skill ?',
      okText: 'Yes, Delete',
      cancelText: 'Discard',
      onOk: () => {
        request(`/api/industryTypes/delete/skill/${item.id}`, 'DELETE')
          .then(() => {
            setSkills(skills.filter((skill) => skill.id !== item.id));
            message.success('Skill Deleted Successfuly');
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const handleEditCancel = () => {
    setLabels(null);
    setIsEditModalVisible(false);
  };

  const showEditModal = (item) => {
    item.label.id = item.id;
    setLabels(item.label);
    // setLabels({ ...labels, id: item.id });
    setIsEditModalVisible(true);
    setLoading(false);
  };

  const skillsColumns = [
    {
      key: 'image',
      title: 'Image',
      render: (item) => (
        <Avatar
          size={64}
          onClick={() => {
            skillImageModal();
          }}
          src={<Image preview={false} src={item.imageUrl} />}
        />
      ),
    },
    {
      title: 'Skills In English',
      key: 'name',
      render: (item) => item.label.en,
    },
    {
      title: 'Skills In Hindi',
      key: 'hindi',
      render: (item) => item.label.hi,
    },
    {
      title: 'Skills In Tamil',
      key: 'tamil',
      render: (item) => item.label.ta,
    },
    {
      title: 'Skills In Hinglish',
      key: 'hinglish',
      render: (item) => item.label.hin,
    },
    {
      title: 'Job Category',
      key: 'industry',
      render: (item) => item.jobCatagory.label.en,
    },

    {
      key: 4,
      title: 'Action',
      render: (item) => (
        <>
          <EditOutlined
            title="Edit"
            style={innerTableActionBtnDesign}
            onClick={() => showEditModal(item)}
          />
          <DeleteOutlined
            title="Ban"
            onClick={() => deleteSkill(item)}
            style={innerTableActionBtnDesign}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ padding: 10 }} gutter={[12, 12]}>
        <Col>
          <b>Total Skills : {skills.length} &nbsp; </b>

          <Button type="primary" onClick={showModal} icon={<PlusOutlined />} />
        </Col>
        <Col>

          

          <Select style={{ width: 200 }} onChange={(e) => filterSkills(e)}>
            {jobCategories.map((cat) => (
              <Option key={cat.id} value={cat.id}>
                {' '}
                {cat.label.en}{' '}
              </Option>
            ))}
          </Select>
          <Button type="danger" onClick={() => fetchData()} icon={<CloseOutlined />} />
        </Col>
      </Row>
      <DataTable
        usersData={skills}
        searchable={false}
        differRows
        pagination={false}
        loading={loading}
        columns={skillsColumns}
      />
      <Modal
        footer=""
        title="Add New Skills"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1400}
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form layout="vertical" form={form} onFinish={handleSkills}>
              <Form.Item
                name="category"
                label="Select Category"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <select
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #d9d9d9',
                  }}
                  onChange={(e) => {
                    console.log(e.target.value.split(','));
                    setSelectedJobCategory([e.target.value.split(',')]);
                  }}
                >
                  <option value="" disabled selected>Select Category</option>
                  {jobCategories.map((cat) => (
                    <option key={cat.id} value={[cat.label.en, cat.id]}>
                      {cat.label.en}
                    </option>
                  ))}
                </select>
              </Form.Item>

              <Form.Item
                name="en"
                label="Enter skills in English"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="hi"
                label="Enter skills in Hindi"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="ta"
                label="Enter skill in Tamil"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="hin"
                label="Enter skills in Hinglish"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="image"
                label="Upload Skill Image"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Upload beforeUpload={() => false} listType="picture">
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Add New Skill
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <DataTable
              usersData={currentSkills}
              pagination={false}
              columns={[
                {
                  key: '0',
                  title: '-',
                  width: 20,
                  render: (item) => (
                    <MinusCircleFilled
                      style={{ fontSize: 18 }}
                      onClick={() => handleCurrentSkills(item.label.en)}
                    />
                  ),
                },
                {
                  key: '6',
                  title: 'Image',
                  render: (item) => <Avatar src={URL.createObjectURL(item.image)} />,
                },
                {
                  key: '1',
                  title: 'Job Category',
                  render: (item) => item.industryLabel[0],
                },
                {
                  key: '2',
                  title: 'English',
                  render: (item) => item.label.en,
                },
                {
                  key: '3',
                  title: 'Hindi',
                  render: (item) => item.label.hi,
                },
                {
                  key: '4',
                  title: 'Tamil',
                  render: (item) => item.label.ta,
                },
                {
                  key: '4',
                  title: 'Hinglish',
                  render: (item) => item.label.hin,
                },
              ]}
            />
            <br />
            <Button
              disabled={!currentSkills.length > 0}
              onClick={() => uploadSkills()}
              type="primary"
            >
              Save Skills
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        footer=""
        title="Edit Skills"
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        width={700}
      >
        {/* <Form layout="vertical" onFinish={updateIndustries}> */}
        <Form.Item
          label="Enter Skill In English"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="en"
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                en: e.target.value,
              }));
            }}
            value={labels?.en}
          />
        </Form.Item>

        <Form.Item
          label="Enter Skill In Hindi"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="hi"
            value={labels?.hi}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                hi: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          label="Enter Skill In Tamil"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="ta"
            value={labels?.ta}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                ta: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          label="Enter Skill In Hinglish"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="hin"
            value={labels?.hin}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                hin: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          name="image"
          label="Upload Skill Image"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                image: e?.fileList[0]?.originFileObj,
              }));
            }}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={() => updateSkills()} htmlType="submit">
            Update Job Category
          </Button>
        </Form.Item>
        {/* </Form> */}
      </Modal>
    </>
  );
};

export { Skills };
