/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable dot-notation */
/* eslint-disable no-constant-condition */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Select } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { request } from '../service/common';
import { DataTable } from './Table/Table';
import { HCLayout } from './Layout/HCLayout';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import './Layout/style.css';

const { Option } = Select;
export const EventNotification = () => {
  const [loading, setLoading] = useState(false);
  const [eventNotifications, setEventNotifications] = useState([]);
  const [events, setEvents] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [createEventModal, setCreateEventModal] = useState(false);
  const [eventId, setEventId] = useState('');
  const [templateId, setTemplateId] = useState('');

  const getEventNotifications = () => {
    setLoading(true);
    request('/api/notification/event-notifications/getAll', 'GET')
      .then((res) => {
        setEventNotifications(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getTemplates = () => {
    request('/api/templates', 'GET').then((res) => {
      setTemplates(res);
    });
  };

  const createEventNotifications = () => {
    setLoading(true);
    request('/api/notification/event-notifications/create', 'POST', {
      data: {
        eventId,
        templateId,
      },
    })
      .then((res) => {
        setEventNotifications([res, ...eventNotifications]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getEvents = () => {
    setLoading(true);
    request('/api/events', 'GET')
      .then((res) => {
        setEvents(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEvents();
    getTemplates();
    getEventNotifications();
  }, []);

  // const onDelete = (record) => {
  //   Modal.confirm({
  //     title: 'Are you sure, you want to delete this event ?',
  //     okText: 'Yes, Delete',
  //     onOk: () => {
  //       setLoading(true);

  //       request(`/api/${record.id}`, 'DELETE')
  //         .then(async () => {
  //           setTemplates(templates.filter((template) => record.id !== template.id));
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           throw err;
  //         });
  //     },
  //   });
  // };

  const actionBtn = [
    <Button type="primary" onClick={() => setCreateEventModal(true)}>
      Create New Event Notification
    </Button>,
  ];

  const columns = [
    {
      key: 1,
      title: 'Event',
      render: (record) => record.event?.eventTitle,
    },
    {
      key: 2,
      title: 'Template',
      render: (record) => record.templateId,
    },
    {
      key: 3,
      title: 'Created On',
      render: (record) => record.createdAt,
    },
    {
      key: 4,
      title: 'Change Event',
      render: (record) => (
        <Select
          defaultValue={events.filter((evnt) => record.eventId === evnt.id)[0].eventTitle}
          className="w-full"
          onChange={(e) => handleEventChange(e)}
        >
          {events.map((evt) => (
            <Option value={evt.id}>{evt.eventTitle}</Option>
          ))}
        </Select>
      ),
    },
    {
      key: 5,
      title: 'Action',
      render: (record) => (
        <Button style={innerTableActionBtnDesign} onClick={() => deleteEvent(record.id)}>
          <DeleteFilled />
        </Button>
      ),
    },
  ];

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Event Notifications"
      actions={actionBtn}
    >
      <DataTable loading={loading} columns={columns} usersData={eventNotifications} />

      <Modal
        onCancel={() => setCreateEventModal(false)}
        visible={createEventModal}
        title="Create New Event Notification"
        onOk={() => createEventNotifications()}
      >
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Select onChange={(e) => setEventId(e)} className="w-full" placeholder="Select Event">
              {events.map((event) => (
                <Option value={event.id}>{event.eventTitle}</Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <Select
              onChange={(e) => setTemplateId(e)}
              className="w-full"
              placeholder="Select Template"
            >
              {templates.map((template) => (
                <Option value={template.id}>{template.title}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </HCLayout>
  );
};
