export const DEPENDENTS = {
  expReq: 'Experience Required',
  jobTitle: 'Job Title',
  genderPreference: 'Gender',
  overtimeSalary: 'Overtime Salary',
  promoted: 'Promoted',
  peopleReq: 'Openings',
  salaryPerShift: 'Salary',
  maxSalary: 'Max Salary',
  skillReq: 'Skill',
  industryType: 'Industry',
};

/**
 *
 * 0 = Input {input type is textbox}
 * 1 = Select {input type is dropdown}
 */

export const DROPDOWNTYPES = ['expReq', 'genderPreference'];

export const EXPERIENCE = {
  0: '0-6 Months',
  1: '1-2 Years',
  2: '2+ Years',
};

export const GENDER = {
  0: 'Male',
  1: 'Female',
  2: 'No Preference',
};

export const ROLE = {
  1: 'Labour',
  2: 'Middlemen',
  3: 'Factory Owner',
  4: 'Worker',
  Labour: 1,
  Middlemen: 2,
  FactoryOwner: 3,
  Worker: 4,
};

export const GETHOURSBYDATES = (dt2, dt1) => {
  let diff = (dt1.getTime() - dt2.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.round(diff);
};

export const GETSECONDSBYDATE = (dt2, dt1) => {
  let diff = (dt1.getTime() - dt2.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.round(diff * 3600).toFixed(1);
};

export const GETMINUTESBYDATES = (dt2, dt1) => {
  let diff = (dt1.getTime() - dt2.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.round(diff * 60);
};
