import { Button, Card, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { request } from '../service/common';

const Resolution = () => {
  // const [loading, setLoading] = useState(false);
  const [resolution, setResolution] = useState([]);

  const getResolution = () => {
    request('/api/companyDetails/resolution', 'GET')
      .then((res) => {
        setResolution(res);
      })
      .catch((err) => console.log(err));
  };

  const updateResolution = (e) => {
    request('/api/companyDetails/resolution', 'PATCH', { data: e })
      .then(() => {
        console.log(resolution);
        setResolution({ ...resolution, width: e.width, height: e.height });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => getResolution(), []);

  return (
    <Card title="Set Resolution">
      <span>
        {' '}
        Current Resolution : {resolution.width}px * {resolution.height}px
      </span>
      <br />
      <br />
      <Form layout="vertical" onFinish={(e) => updateResolution(e)}>
        <Form.Item
          label="Width"
          name="width"
          rules={[{ required: true, message: 'Width is required' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Height"
          name="height"
          rules={[{ required: true, message: 'height is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export { Resolution };
