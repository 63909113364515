/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Button, Card, message, Upload, Avatar, Modal, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { request } from '../service/common';

const RefImg = () => {
  // const [loading, setLoading] = useState(false);

  const [refImg, setRefImg] = useState([]);

  const [file, setFile] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const getRefImg = () => {
    // setLoading(true);
    request('/api/app-user/getReferralImage', 'GET')
      .then((data) => {
        setRefImg(data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getRefImg();
    return () => {
      setRefImg();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (file.length < 1) {
      // setLoading(false);
      message.warn('Please select any file');
    } else {
      // setLoading(true);
      file.forEach((fylerImage) => {
        request('/api/app-user/updateReferralImage', 'PATCH', {
          formData: {
            image: fylerImage.originFileObj,
          },
        })
          .then(() => {
            // setLoading(true);
            // setRefImg([res]);
          })
          .catch((err) => console.log('err', err))
          .finally(() => setTimeout(getRefImg, 2000));
      });
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: () => false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        setFile(info.fileList);
      }
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Card title="Referral Image">
        <div className="newImg mb-3">
          <h3 className="inline mx-2">Upload new Referral Image :</h3>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
          <Button className="mt-2" onClick={handleSubmit}>
            Add Image
          </Button>
        </div>
        <div className="currentImg">
          <h3 className="mx-2">Current Referral Image :</h3>
          <Avatar onClick={showModal} shape="square" size={128} src={refImg.referralImage} />
        </div>
      </Card>
      <Modal
        title="Current Referral Image"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <Image preview={false} src={refImg.referralImage} width={450} />
      </Modal>
    </>
  );
};

export { RefImg };
