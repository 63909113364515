/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { Drawer, Button, Row, Col, Tooltip } from 'antd';
import QS from 'query-string';
import { EyeOutlined } from '@ant-design/icons';
import { DataTable } from './Table/Table';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import { Desc } from './Common/Description';
import { request } from '../service/common';
import { GETHOURSBYDATES, GETMINUTESBYDATES, GETSECONDSBYDATE } from './Hepler';

export const SessionData = () => {
  const [session, setSession] = useState([]);

  const [loading, setLoading] = useState(true);

  const [drawer, setDrawer] = useState(false);

  const [siderProps, setSiderProps] = useState({});

  const [page, setPage] = useState(1);

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [filters] = useState({});

  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);
  const refreshTable = (queryString) => {
    setLoading(true);
    request(
      queryString ? `/api/session?page=${page}&${queryString}` : `/api/session?page=${page}`,
      'GET',
    )
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            item.key = item._id;
            if (item.buttonClicks === 'undefined') {
              item.buttonClicks = { button: 0 };
            }
          });

          setSession(data);
        } else {
          if (page === 1) setSession(data);
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const requestsCaller = () => {
    setLoading(true);

    request(`/api/session`, 'GET')
      .then(async (data) => {
        data.map((item) => {
          item.key = item._id;
          if (item.buttonClicks === 'undefined') {
            item.buttonClicks = { button: 0 };
          }
        });
        setSession(data);
        setLoading(false);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    requestsCaller();
  }, []);

  useEffect(() => {
    refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
  }, [page]);

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
  }, [isFilterChanged]);

  const onDrawerClose = () => {
    setSiderProps({});
    setDrawer(false);
  };
  const onDrawerOpen = (record) => {
    setSiderProps({
      title: record._id,
      data: record,
    });
    setDrawer(true);
  };

  // const onDateChange = (date, dateString) => {
  //   if (dateString.length) {
  //     setPage(1);
  //     onTableFilterChange({
  //       date: dateString,
  //     });
  //   } else clearFilter('date');
  //   setFilterChange();
  // };

  // const onTableFilterChange = (query) => {
  //   setFilters({ ...filters, ...query });
  // };

  // const clearFilter = (type) => {
  //   setPage(1);
  //   setFilters({ ...filters, [type]: undefined });
  //   setIsFilterChanged(true);
  // };

  // const setFilterChange = () => {
  //   setIsFilterChanged(true);
  //   // setFilters({ ...filters, isChanged: true });
  // };

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  const columns = [
    {
      key: 'Session ID',
      title: 'Session ID',
      render: (record) => record._id,
    },
    {
      key: 'User ID',
      title: 'User ID',
      render: (record) => record.userId,
    },
    // {
    //   key: 'Button Clicks',
    //   title: 'Total Clicks',
    //   render: (record) => {
    //     let totalClicks = 0;
    //     if (record.buttonClicks !== undefined) {
    //       Object.entries(record.buttonClicks).map((obj) => {
    //         console.log(obj);
    //         totalClicks +=  parseInt(obj[0], 10);
    //       });
    //     }

    //     return totalClicks;
    //   },
    // },

    {
      key: 'duration',
      title: 'Duration',
      render: (record) => (
        <Tooltip placement="top" title={record.endTime}>
          {record.endTime
            ? `${GETHOURSBYDATES(
                new Date(record.createdAt),
                new Date(record.endTime),
              )} : ${GETMINUTESBYDATES(
                new Date(record.createdAt),
                new Date(record.endTime),
              )} : ${GETSECONDSBYDATE(new Date(record.createdAt), new Date(record.endTime))}`
            : null}
        </Tooltip>
      ),

      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },
    {
      key: 'createdAt',
      title: 'Start Time',
      render: (record) => (
        <Tooltip placement="top" title={`${new Date(record.createdAt).toISOString()}`}>
          {`${new Date(record.createdAt).toLocaleString()}`}
        </Tooltip>
      ),
      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },

    {
      key: 'endTime',
      title: 'End Time',
      render: (record) => (
        <Tooltip placement="top" title={record.endTime}>
          {record.endTime ? new Date(record.endTime).toLocaleString() : 'N/A'}
        </Tooltip>
      ),

      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },

    {
      key: 7,
      title: 'Action',
      width: 200,
      render: (record) => (
        <>
          <EyeOutlined
            title="View"
            style={innerTableActionBtnDesign}
            onClick={() => {
              onDrawerOpen(record);
            }}
          />
        </>
      ),
    },
  ];

  const data = siderProps.data || {};

  return (
    <>
      <DataTable
        usersData={session}
        searchable={false}
        loading={loading}
        pagination={false}
        columns={columns}
      />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
      <Drawer
        title={siderProps.title}
        width="750px"
        placement="right"
        onClose={onDrawerClose}
        visible={drawer}
      >
        <Row>
          <Col span={12} lg={12} md={12} sm={32} xs={32}>
            <Desc title="Session ID" content={data?._id} />
            <Desc title="User ID" content={data?.userId} />
            <Desc title="Created At" content={data?.createdAt?.substring(0, 10)} />
            <Desc
              title="Button Clicks"
              content={
                data.buttonClicks
                  ? Object.entries(JSON.parse(data.buttonClicks)).map((obj) => {
                      // eslint-disable-next-line react/jsx-indent
                      console.log('obj', obj);
                      return obj[1] !== 0 ? <Desc title={obj[0]} content={obj[1]} /> : null;
                    })
                  : null
              }
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
