// Testing;

export const APPDATA = {
  domainURIPrefix: 'https://aamdhane.page.link',
  dynamicLink: 'https://aamdhane.page.link/jobDetails?',
  androidPackageName: 'com.aamdhane.app',
};
const WebApiKey = 'AIzaSyA55bWr56z_YXQkNeO5lhSME7oizo2GKjk';

// Production

// export const APPDATA = {
//   domainURIPrefix: 'https://aamdhaneapp.page.link',
//   dynamicLink: 'https://aamdhaneapp.page.link/jobDetails?',
//   androidPackageName: 'com.aamdhane.app',
// };

// const WebApiKey = 'AIzaSyDYqwAN-cnmsGiGt-h4Zf6GyX7MTjAxZEU';

export const DYNAMICLINKAPI = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${WebApiKey}`;

export const TagStatus = {
  Active: 1,
  Inactive: 0,
  0: 'InActive',
  1: 'Active',
};

export const VarificationStatus = {
  Pending: 0,
  Approved: 1,
  Rejected: 2,
  0: 'Pending',
  1: 'Approved',
  2: 'Rejected',
};
