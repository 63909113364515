/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Input,
  Form,
  Row,
  Col,
  Drawer,
  Modal,
  message,
  Alert,
  Switch,
  Tooltip,
  DatePicker,
  Select,
} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  ExclamationOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import QS from 'query-string';

import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';
import { Desc } from './Common/Description';
import { request } from '../service/common';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import { AuthContext } from '../context/Authcontext';
import './Layout/style.css';

const { Option } = Select;

const Worker = () => {
  const [worker, setWorker] = useState([]);

  const [loading, setLoading] = useState(true);

  const [modalVisibility, setModalVisibility] = useState(false);

  const [drawer, setDrawer] = useState(false);

  const [siderProps, setSiderProps] = useState({});

  const [editData, setEditData] = useState({});

  const [editModalVisiblity, setEditModalVisiblity] = useState(false);

  const { userContext } = useContext(AuthContext);

  const [page, setPage] = useState(1);

  const [totalWorkers, setTotalWorkers] = useState(0);

  const [bannedWorkers, setBannedWorkers] = useState(0);

  const [showTrash, setShowTrash] = useState(false);

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [filters, setFilters] = useState({});

  const [disableAddWorkersButton, setDisableAddWorkersButton] = useState(true);

  const [selectedWorkersFile, setSelectedWorkersFile] = useState(null);

  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);

  const [name, setName] = useState('');

  const [phone, setPhone] = useState('');

  const [employeeCode, setEmployeeCode] = useState('');

  const [department, setDepartment] = useState('');

  const [designation, setDesignation] = useState('');

  const [dateOfJoining, setDateOfJoining] = useState(undefined);

  const [dateOfLeaving, setDateOfLeaving] = useState(undefined);

  const [factory, setFactory] = useState(undefined);

  const [factories, setFactories] = useState([]);

  const refreshTable = (queryString) => {
    setLoading(true);
    request(
      queryString ? `/api/worker?page=${page}&${queryString}` : `/api/worker?page=${page}`,
      'GET',
    )
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            item.key = item.id;
          });

          setWorker(data);
          request(`/api/getAll/mills`)
            .then(async (data) => {
              setFactories(data);
            })
            .catch((err) => console.log(err));
        } else {
          if (page === 1) setWorker(data);
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const getTrash = (val) => {
    setShowTrash(val);
    setPage(1);
    onTableFilterChange({ isBanned: val });
    if (val) {
      setFilterChange();
    } else {
      clearFilter('isBanned');
    }
  };

  useEffect(() => {
    refreshTable();

    request(`/api/count/workers`, 'GET')
      .then(async (data) => {
        setTotalWorkers(data.totalWorkers);
        setBannedWorkers(data.bannedWorkers);
      })
      .catch((err) => {
        throw err;
      });
      return () => {
        setTotalWorkers();
        setBannedWorkers();
      }
  }, []);

  useEffect(() => {
    refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
    return () => {
      setPrevBtnDisabled();
    }
  }, [page]);

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
    return () => {
      setIsFilterChanged();
    }
  }, [isFilterChanged]);

  const onDateChange = (date, dateString) => {
    if (dateString.length) {
      setPage(1);
      onTableFilterChange({
        date: dateString,
      });
    } else clearFilter('date');
    setFilterChange();
  };

  const dateOfJoiningHandler = (date, dateString) => {
    setDateOfJoining(dateString);
  };

  const dateOfLeavingHandler = (date, dateString) => {
    setDateOfLeaving(dateString);
  };

  const onTableFilterChange = (query) => {
    setFilters({ ...filters, ...query });
  };

  const clearFilter = (type) => {
    setPage(1);
    setFilters({ ...filters, [type]: undefined });
    setIsFilterChanged(true);
  };

  const setFilterChange = () => {
    setIsFilterChanged(true);
  };

  const onWorkerUpload = () => {
    const fileObj = { file: selectedWorkersFile };
    setDisableAddWorkersButton(false);
    setLoading(true);
    request(`/api/worker/bulk`, 'POST', {
      formData: fileObj,
    })
      .then(async () => {
        message.success('Workers excel uploaded successfully');
        refreshTable();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setDisableAddWorkersButton(true);
        setSelectedWorkersFile(null);
      });
  };

  const finalDelete = (record) => {
    Modal.confirm({
      title: 'This action is not reversable',
      icon: <ExclamationOutlined />,
      content: 'Please be careful while deleteting permanently. this action is undoable',
      onOk: () => {
        setLoading(true);
        request(`/api/worker/delete?userId=${record.id}`, 'DELETE')
          .then(() => {
            setWorker(worker.filter((workr) => workr.id !== record.id));
            setLoading(false);
          })
          .catch((err) => console.log(err));
      },
      okText: 'Delete',
    });
  };

  const onAddWorker = () => {
    setModalVisibility(true);
  };

  const cancelForm = () => {
    setModalVisibility(false);
    setName('');
    setPhone('');
    setEmployeeCode(false);
    setDepartment('');
    setDesignation('');
  };

  const addWorker = () => {
    setLoading(true);
    const worker = {
      name,
      phone,
      employeeCode,
      department,
      designation,
      dateOfJoining,
      dateOfLeaving,
      millId: factory,
    };
    request(`/api/worker`, 'POST', {
      data: worker,
    })
      .then(async (data) => setWorker([data, ...worker]))
      .catch((err) => console.log(err))
      .finally(() => {
        setModalVisibility(false);
        setLoading(false);
        setName('');
        setPhone('');
        setEmployeeCode('');
        setDepartment('');
        setDesignation('');
        setDateOfJoining(undefined);
        setDateOfLeaving(undefined);
        setFactory(undefined);
      });
  };

  const actionBtn = [
    <Row gutter={16} key="1">
      <Col>
        <div style={{ paddingTop: 5 }}>
          Trash: &nbsp;
          <Switch defaultChecked={showTrash} onChange={getTrash} />
        </div>
      </Col>
      <Col>
        <Button type="primary" onClick={onAddWorker}>
          Add New Worker
        </Button>
      </Col>
      <Col>
        <Input
          type="file"
          style={{ border: 'none', width: '300px' }}
          onChange={(e) => setSelectedWorkersFile(e.target.files[0])}
          required
        />
        <Button type="primary" onClick={() => onWorkerUpload()} disabled={!disableAddWorkersButton}>
          Add Workers
        </Button>
      </Col>
      <Col>
        <Button
          type="primary"
          onClick={() => {
            setPage(1);
            refreshTable();
          }}
        >
          <ReloadOutlined />
        </Button>
      </Col>
      <Col>
        {userContext.access['download'][0] ? (
          <Link to="/export/workers" target="_blank">
            <Button
              className="w-44"
              type="primary"
              style={{ border: 'none' }}
              onClick={() => {
                message.success('The file is downloading');
                localStorage.setItem('reqData', '2');
              }}
            >
              Export to CSV
              {/* <CSV
              filename="Workers"
              data={allWorkers.map((worker) => {
                const updatedWorker = { ...worker };
                // updatedWorker.dateOfJoining = new Date(
                //   updatedWorker.dateOfJoining,
                // ).toLocaleTimeString('en-US', {
                //   timeZone: 'Asia/Jakarta',
                // });
                updatedWorker.name = updatedWorker.userInfo.name;
                updatedWorker.phone = updatedWorker.userInfo.phone;
                updatedWorker.millName = updatedWorker.mill ? updatedWorker.mill.millName : 'N/A';
                updatedWorker.isBanned = !updatedWorker.userInfo.isBanned ? 'false' : 'true';
                delete updatedWorker.id;
                delete updatedWorker.userId;
                delete updatedWorker.millId;
                delete updatedWorker.updatedAt;
                delete updatedWorker.userInfo;
                delete updatedWorker.mill;
                return updatedWorker;
              })}
            /> */}
            </Button>
          </Link>
        ) : null}
      </Col>
    </Row>,
  ];

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  const onEdit = (record) => {
    setEditModalVisiblity(true);
    setEditData(record);
  };

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to Ban this worker',
      okText: 'Yes, Ban',
      onOk: () => {
        setLoading(true);
        request(`/api/worker?userId=${record.id}`, 'DELETE')
          .then(async () => {
            setWorker(
              worker.map((worker) =>
                worker.id === record.id ? { ...worker, isBanned: true } : worker,
              ),
            );

            setBannedWorkers(bannedWorkers + 1);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          });
      },
    });
  };

  const onUnban = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to un-ban this worker',
      okText: 'Yes, Un-ban',
      onOk: () => {
        setLoading(true);
        request(`/api/worker/restore?userId=${record.id}`, 'PATCH')
          .then(async () => {
            setWorker(worker.filter((worker) => worker.id !== record.id));
            setBannedWorkers(bannedWorkers - 1);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          });
      },
    });
  };

  const onDrawerClose = () => {
    setSiderProps({});
    setDrawer(false);
  };
  const onDrawerOpen = (record) => {
    setSiderProps({
      title: record.name,
      data: record,
    });
    setDrawer(true);
  };

  const onEditModalClose = () => {
    setEditModalVisiblity(false);
    setEditData({});
  };

  const editModalSave = () => {
    setEditModalVisiblity(false);
    setLoading(true);
    request(`/api/worker?workerId=${editData.worker.id}`, 'PATCH', {
      data: {
        name: editData.name,
        employeeCode: editData.worker.employeeCode,
        department: editData.worker.department,
        designation: editData.worker.designation,
        dateOfJoining: editData.worker.dateOfJoining,
        dateOfLeaving: editData.worker.dateOfLeaving,
      },
    })
      .then(async () => {
        setWorker(worker.map((item) => (item.id === editData.id ? editData : item)));
        setLoading(false);
        setEditData({});
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  const data = siderProps.data || {};

  const columns = [
    {
      key: 'id',
      title: 'ADE ID',
      render: (record) => record.id,
      // filterDropdown: () => (
      //   <Row className="p-3 shadow-lg">
      //     <Col>
      //       <Input
      //         placeholder="Search Here"
      //         value={filters.id}
      //         autoFocus
      //         onChange={(e) => {
      //           setPage(1);
      //           onTableFilterChange({
      //             id: e.target.value,
      //           });
      //         }}
      //       />
      //     </Col>
      //     <Col>
      //       <Button
      //         onClick={() => {
      //           setFilterChange();
      //         }}
      //         icon={<SearchOutlined />}
      //         type="primary"
      //         style={{ borderRadius: 0 }}
      //       />
      //     </Col>
      //     <Col>
      //       <Button
      //         onClick={() => clearFilter('id')}
      //         icon={<CloseOutlined />}
      //         type="default"
      //         style={{ borderRadius: 0, background: 'red', color: 'white' }}
      //       />
      //     </Col>
      //   </Row>
      // ),
      // filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'employeeCode',
      title: 'Emp. Code',
      render: (record) => record.worker.employeeCode,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.employeeCode}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  employeeCode: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('employeeCode')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'name',
      title: 'Name',
      render: (record) => record.name,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.name}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  name: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('name')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'department',
      title: 'Dept.',
      render: (record) => record.worker.department,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.department}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  department: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('department')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'designation',
      title: 'Designation',
      render: (record) => record.worker.designation,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.designation}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  designation: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('designation')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'dateOfJoining',
      title: 'Date of Joining',
      render: (record) => (
        <Tooltip
          placement="top"
          title={
            record.worker.dateOfJoining
              ? `${new Date(record.worker.dateOfJoining).toLocaleDateString('en-US', {
                  timeZone: 'Asia/Jakarta',
                })} ${new Date(record.worker.dateOfJoining).toLocaleTimeString('en-US', {
                  timeZone: 'Asia/Jakarta',
                })}`
              : 'N/A'
          }
        >
          {record.worker.dateOfJoining
            ? `${new Date(record.worker.dateOfJoining).toLocaleDateString('en-US', {
                timeZone: 'Asia/Jakarta',
              })}`
            : 'N/A'}
        </Tooltip>
      ),
      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },
    {
      key: 'phone',
      title: 'Phone No.',
      render: (record) => record.phone,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.phone}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  phone: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('phone')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'millName',
      title: 'Mill Name',
      render: (record) => (record.worker.mill ? record.worker.mill.millName : 'N/A'),
      // filterDropdown: () => (
      //   <Row className="p-3 shadow-lg">
      //     <Col>
      //       <Input
      //         placeholder="Search Here"
      //         value={filters.phone}
      //         autoFocus
      //         onChange={(e) => {
      //           setPage(1);
      //           onTableFilterChange({
      //             phone: e.target.value,
      //           });
      //         }}
      //       />
      //     </Col>
      //     <Col>
      //       <Button
      //         onClick={() => {
      //           setFilterChange();
      //         }}
      //         icon={<SearchOutlined />}
      //         type="primary"
      //         style={{ borderRadius: 0 }}
      //       />
      //     </Col>
      //     <Col>
      //       <Button
      //         onClick={() => clearFilter('phone')}
      //         icon={<CloseOutlined />}
      //         type="default"
      //         style={{ borderRadius: 0, background: 'red', color: 'white' }}
      //       />
      //     </Col>
      //   </Row>
      // ),
      // filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'dateOfLeaving',
      title: 'Date of Leaving',
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.worker.dateOfLeaving).toLocaleDateString('en-US', {
            timeZone: 'Asia/Jakarta',
          })} ${new Date(record.worker.dateOfLeaving).toLocaleTimeString('en-US', {
            timeZone: 'Asia/Jakarta',
          })}`}
        >
          {record.worker.dateOfLeaving
            ? `${new Date(record.worker.dateOfLeaving).toLocaleDateString('en-US', {
                timeZone: 'Asia/Jakarta',
              })}`
            : 'N/A'}
        </Tooltip>
      ),
      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },
    {
      key: 'status',
      title: 'Status',
      render: (record) =>
        record.worker.dateOfJoining && !record.worker.dateOfLeaving ? 'Working' : 'Not Working',
    },
    {
      key: 'createdAt',
      title: 'Reg. Date',
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.createdAt).toLocaleDateString()} ${new Date(
            record.createdAt,
          ).toLocaleTimeString()}`}
        >
          {`${new Date(record.createdAt).toLocaleDateString()}`}
        </Tooltip>
      ),
      filterDropdown: () => {},
      filterIcon: () => <DatePicker style={{ width: 40 }} onChange={onDateChange} />,
    },
    {
      key: 7,
      title: 'Action',
      width: 200,
      render: (record) => (
        <>
          <EyeOutlined
            title="View"
            style={innerTableActionBtnDesign}
            onClick={() => {
              onDrawerOpen(record);
            }}
          />
          {userContext.access['labourers'][2] ? (
            <EditOutlined
              title="Edit"
              style={innerTableActionBtnDesign}
              onClick={() => onEdit(record)}
            />
          ) : null}
          {record.isBanned === true ? (
            <ReloadOutlined
              title="Unban"
              style={innerTableActionBtnDesign}
              onClick={() => onUnban(record)}
            />
          ) : userContext.access['labourers'][3] ? (
            <DeleteOutlined
              title="Ban"
              style={innerTableActionBtnDesign}
              onClick={() => onDelete(record)}
            />
          ) : null}

          {showTrash ? (
            <DeleteOutlined
              title="Delete Permanently"
              style={innerTableActionBtnDesign}
              onClick={() => finalDelete(record)}
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Workers"
      actions={actionBtn}
    >
      <Row gutter={24} className="p-3">
        <Col span={12} xs={24} md={12} sm={12} lg={12} className="gutter-row ">
          <div className="tileStyle">
            <h2>Total Workers</h2>
            <span>{`${totalWorkers}`}</span>
          </div>
        </Col>
        <Col xs={24} md={12} sm={12} lg={12}>
          <div className="tileStyle">
            <h2>Banned Workers</h2>
            <span>{`${bannedWorkers}`}</span>
          </div>
        </Col>
      </Row>
      {showTrash ? (
        <Alert
          type="warning"
          message="Worker in trash will be removed automatically after 30 days"
          showIcon
        />
      ) : null}
      <DataTable
        usersData={worker}
        searchable={false}
        differRows
        pagination={false}
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
      />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
      <Drawer
        title={`${siderProps.title}'s information`}
        width="750px"
        placement="right"
        onClose={onDrawerClose}
        visible={drawer}
      >
        <Row>
          <Col span={12} lg={12} md={12} sm={32} xs={32}>
            <Desc title="Name" content={data.name} />
            <Desc title="Phone" content={data.phone} />
            <Desc title="Employee Code" content={data.worker?.employeeCode} />
            <Desc title="Department" content={data.worker?.department} />
          </Col>
          <Col span={12} lg={12} md={12} sm={32} xs={32}>
            <Desc title="Designation" content={data.worker?.designation} />
            <Desc
              title="Date of Joining"
              content={new Date(data.worker?.dateOfJoining).toLocaleDateString('en-US', {
                timeZone: 'Asia/Jakarta',
              })}
            />
            <Desc
              title="Date of Leaving"
              content={
                data.worker?.dateOfLeaving
                  ? new Date(data.worker?.dateOfLeaving).toLocaleDateString('en-US', {
                      timeZone: 'Asia/Jakarta',
                    })
                  : 'N/A'
              }
            />
            <Desc title="Factory Name" content={data.worker?.mill?.millName} />
          </Col>
        </Row>
      </Drawer>
      <Modal
        title="Add New Worker"
        visible={modalVisibility}
        onCancel={cancelForm}
        onOk={addWorker}
      >
        <label>Name : </label>
        <Input
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="Enter Name"
        />
        <label>Phone : </label>
        <Input
          type="text"
          placeholder="Enter Phone"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <label>Employee Code : </label>
        <Input
          type="text"
          placeholder="Enter Employee Code"
          onChange={(e) => {
            setEmployeeCode(e.target.value);
          }}
        />
        <label>Department : </label>
        <Input
          type="text"
          placeholder="Enter Department"
          onChange={(e) => {
            setDepartment(e.target.value);
          }}
        />
        <label>Designation : </label>
        <Input
          type="text"
          placeholder="Enter Designation"
          onChange={(e) => {
            setDesignation(e.target.value);
          }}
        />
        <label>Date of Joining : </label>
        <DatePicker onChange={dateOfJoiningHandler} />

        <br />
        <label>Date of Leaving : </label>
        <DatePicker onChange={dateOfLeavingHandler} />
        <br />
        <label>Mill Name :</label>
        <Select style={{ width: '100%' }} onChange={(e) => setFactory(e)}>
          {factories.map((mill) => (
            <Option value={mill.id}>{mill.millName}</Option>
          ))}
        </Select>
      </Modal>
      <Modal
        title="Edit Worker"
        visible={editModalVisiblity}
        onCancel={onEditModalClose}
        onOk={editModalSave}
        okText="Update Worker Info"
      >
        <Form.Item label="Name">
          <Input
            title="Name"
            value={editData.name}
            onChange={(e) => {
              setEditData({
                ...editData,
                name: e.target.value,
              });
            }}
            placeholder="Enter Name"
            required
          />
        </Form.Item>
        <Form.Item label="Employee Code">
          <Input
            title="Employee Code "
            value={editData.worker?.employeeCode}
            onChange={(e) => {
              setEditData({
                ...editData,
                worker: { ...editData.worker, employeeCode: e.target.value },
              });
            }}
            placeholder="Enter Employee Code"
            required
          />
        </Form.Item>
        <Form.Item label="Department">
          <Input
            title="Department "
            value={editData.worker?.department}
            onChange={(e) => {
              setEditData({
                ...editData,
                worker: { ...editData.worker, department: e.target.value },
              });
            }}
            placeholder="Enter Department"
            required
          />
        </Form.Item>
        <Form.Item label="Designation">
          <Input
            title="Designation "
            value={editData.worker?.designation}
            onChange={(e) => {
              setEditData({
                ...editData,
                worker: { ...editData.worker, designation: e.target.value },
              });
            }}
            placeholder="Enter Designation"
            required
          />
        </Form.Item>
        <Form.Item label="Date of Joining">
          <DatePicker
            defaultValue={
              editData.worker?.dateOfJoining ? moment(editData.worker?.dateOfJoining) : moment()
            }
            onChange={(date, dateString) => {
              setEditData({
                ...editData,
                worker: { ...editData.worker, dateOfJoining: dateString },
              });
            }}
          />
        </Form.Item>
        <Form.Item label="Date of Leaving">
          <DatePicker
            defaultValue={
              editData.worker?.dateOfLeaving ? moment(editData.worker?.dateOfLeaving) : moment()
            }
            onChange={(date, dateString) => {
              setEditData({
                ...editData,
                worker: { ...editData.worker, dateOfLeaving: dateString },
              });
            }}
          />
        </Form.Item>
      </Modal>
    </HCLayout>
  );
};

export { Worker };
