/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, message, Tooltip } from 'antd';
import QS from 'query-string';
import { DataTable } from './Table/Table';
import { HCLayout } from './Layout/HCLayout';

import { request } from '../service/common';

const ServerAvailability = () => {
  const [serverAvailability, setServerAvailability] = useState([]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [filters] = useState({});

  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);

  const refreshTable = (queryString) => {
    setLoading(true);
    request(
      queryString
        ? `/api/server-availability?page=${page}&${queryString}`
        : `/api/server-availability?page=${page}`,
      'GET',
    )
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            item.key = item.id;
          });

          setServerAvailability(data);
        } else {
          if (page === 1) setServerAvailability(data);
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };

  const requestsCaller = () => {
    setLoading(true);

    request(`/api/server-availability`, 'GET')
      .then(async (data) => {
        data.map((item) => {
          item.key = item.id;
        });
        setServerAvailability(data);
        setLoading(false);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    requestsCaller();
    return () => {
      setServerAvailability();
    }
  }, []);

  useEffect(() => {
    refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
  }, [page]);

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
  }, [isFilterChanged]);

  // const onDateChange = (date, dateString) => {
  //   if (dateString.length) {
  //     setPage(1);
  //     onTableFilterChange({
  //       date: dateString,
  //     });
  //   } else clearFilter('date');
  //   setFilterChange();
  // };

  // const onTableFilterChange = (query) => {
  //   setFilters({ ...filters, ...query });
  // };

  // const clearFilter = (type) => {
  //   setPage(1);
  //   setFilters({ ...filters, [type]: undefined });
  //   setIsFilterChanged(true);
  // };

  // const setFilterChange = () => {
  //   setIsFilterChanged(true);
  //   // setFilters({ ...filters, isChanged: true });
  // };

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  const actionBtn = [
    <Row gutter={16} key="1">
      <Col>
        <Link to="/export/server-availability" target="_blank">
          <Button
            className="w-44"
            type="primary"
            style={{ border: 'none' }}
            onClick={() => {
              message.success('The file is downloading');
            }}
          >
            Export to CSV
            {/* <CSV
            filename="ServerAvailability"
            data={allServerAvailability.map((record) => {
              const updatedRecord = { ...record };
              updatedRecord.status = updatedRecord.code.toString(10)[0] === '5' ? 'Down' : 'Up';
              updatedRecord.timeStamp = updatedRecord.createdAt;
              delete updatedRecord.code;
              delete updatedRecord.createdAt;
              return updatedRecord;
            })}
          /> */}
          </Button>
        </Link>
      </Col>
    </Row>,
  ];

  const columns = [
    {
      key: 'Request',
      title: 'Request',
      render: (record) => record.request,
    },
    {
      key: 'code',
      title: 'Up/down status',
      render: (record) => (record.code.toString(10)[0] === '5' ? 'Down' : 'Up'),
    },
    {
      key: 'createdAt',
      title: 'TimeStamp',
      render: (record) => (
        <Tooltip placement="top" title={`${new Date(record.createdAt).toISOString()}`}>
          {`${new Date(record.createdAt).toISOString()}`}
        </Tooltip>
      ),
      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },
  ];

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Server Availability"
      actions={actionBtn}
    >
      <DataTable
        usersData={serverAvailability}
        searchable={false}
        loading={loading}
        pagination={false}
        columns={columns}
      />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
    </HCLayout>
  );
};

export { ServerAvailability };
