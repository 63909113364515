/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { message, Button, Modal, Form, Input, Row, Col, Select } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import { request } from '../service/common';
import { DataTable } from './Table/Table';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';
import './Layout/style.css';

const JobTitles = () => {
  const [titles, setTitles] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedJobCategory, setSelectedJobCategory] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [labels, setLabels] = useState();
  const [loading, setLoading] = useState(true);
  const [currentTitles, setCurrentTitles] = useState([]);
  const [form] = Form.useForm();

  const { Option } = Select;
  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchData();
    industries();
  }, []);

  const fetchData = () => {
    setLoading(true);
    request('/api/industryTypes/jobTitles')
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        message.success('Data loaded');
        setTitles(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const industries = () => {
    setLoading(true);
    request('/api/jobCategories')
      .then(async (data) => {
        // console.log(data);
        setJobCategories(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const showEditModal = (item) => {
    item.label.id = item.id;
    setLabels(item.label);
    // setLabels({ ...labels, id: item.id });
    setIsEditModalVisible(true);
    setLoading(false);
  };

  const handleTitles = (e) => {
    console.log(e);

    if(selectedJobCategory.length === 0){
      message.error('Please select Job Category');
      return
    }

    setCurrentTitles([
      ...currentTitles,
      {
        label: { en: e.en, hi: e.hi, ta: e.ta, hin: e.hin },
        categoryId: selectedJobCategory[1],
        // categoryId: e.jobCategory[1],
        // industryLabel: e.jobCategory[0],
        industryLabel: selectedJobCategory[0],
      },
    ]);
    form.resetFields();
    setSelectedJobCategory([]);
  };

  // useEffect(() => {
  //   console.log(currentTitles);
  // }, [currentTitles]);

  const handleCurrentTitles = (label) => {
    setCurrentTitles(currentTitles.filter((item) => item.label.en !== label));
  };

  const uploadTitles = () => {
    setLoading(true);
    form.resetFields();
    setIsModalVisible(false);
    request('/api/industryTypes/create/jobTitles', 'POST', {
      data: currentTitles,
    }).then(async (res) => {
      console.log(res);
      setJobCategories([...jobCategories, res]);
      setLoading(false);
    });
  };

  const updateJobTitles = () => {
    setLoading(true);
    console.log(loading);
    request(`/api/industryTypes/update/jobTitles/${labels.id}`, 'PATCH', {
      data: {
        en: labels.en,
        hi: labels.hi,
        ta: labels.ta,
        hin: labels.hin,
      },
    })
      .then(async (res) => {
        setLoading(false);
        console.log(res);
        setTimeout(fetchData, 1000);
        setIsEditModalVisible(false);
      })
      .catch((err) => {
        console.log(err);
        message.error('Failed to upload');
      });
  };

  const deleteJobTitle = (item) => {
    Modal.confirm({
      title: 'Are you sure ?',
      content:
        'This action cannot be un-done. are you sure you want to delete Job Title ?',
      okText: 'Yes, Delete',
      cancelText: 'Discard',
      onOk: () => {
        request(`/api/industryTypes/delete/jobTitle/${item.id}`, 'DELETE')
          .then(() => {
            setTitles(titles.filter((title) => title.id !== item.id));
            message.success('Job Category deleted Successfuly');
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const filterTitles = (e) => {
    console.log(e);
    setLoading(true);
    request(`/api/industryTypes/jobTitles?id=${e}`)
      .then(async (data) => {
        message.success('Data loaded');
        console.log(data);
        setTitles(data);
        setLoading(false);
      })
      .catch((err) => {
        message.error('Failed to load data');
        console.log(err);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEditCancel = () => {
    setLabels(null);
    setIsEditModalVisible(false);
  };

  const jobsColumns = [
    {
      title: 'In English',
      key: 'name',
      render: (record) => record.label.en,
    },
    {
      title: 'In Hindi',
      key: 'hindi',
      render: (record) => record.label.hi,
    },
    {
      title: 'In Tamil',
      key: 'tamil',
      render: (record) => record.label.ta,
    },
    {
      title: 'In Hinglish',
      key: 'Hinglish',
      render: (record) => record.label.hin,
    },
    {
      title: 'Job Category',
      key: 'Hinglish',
      render: (record) => `${record.jobCatagory.label.en} `,
    },
    {
      key: 4,
      title: 'Action',
      render: (item) => (
        <>
          <EditOutlined
            title="Edit"
            style={innerTableActionBtnDesign}
            onClick={() => showEditModal(item)}
          />
          <DeleteOutlined
            title="Ban"
            onClick={() => deleteJobTitle(item)}
            style={innerTableActionBtnDesign}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ padding: 10 }} gutter={[12, 12]}>
        <Col>
          <b>Total Job titles : {titles.length} &nbsp; </b>

          <Button type="primary" onClick={showModal} icon={<PlusOutlined />} />
        </Col>
        <Col>
          <Select style={{ width: 200 }} onChange={(e) => filterTitles(e)}>
            {jobCategories.map((ind) => (
              <Option key={ind.id} value={ind.id}>
                {' '}
                {ind.label.en}{' '}
              </Option>
            ))}
          </Select>
          <Button
            type="danger"
            onClick={() => fetchData()}
            icon={<CloseOutlined />}
          />
        </Col>
      </Row>
      <DataTable
        usersData={titles}
        searchable={false}
        differRows
        pagination={false}
        loading={loading}
        columns={jobsColumns}
      />

      <Modal
        footer=""
        title="Add New Job Titles"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1400}
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form layout="vertical" form={form} onFinish={handleTitles}>
              <Form.Item
                name="jobCategory"
                label="Select Job Category"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                
                <select
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #d9d9d9',
                  }}
                  onChange={(e) => {
                    console.log(e.target.value.split(','));
                    setSelectedJobCategory([e.target.value.split(',')]);
                  }}
                >
                  <option value="" disabled selected>Select Category</option>
                  {jobCategories.map((cat) => (
                    <option key={cat.id} value={[cat.label.en, cat.id]}>
                      {cat.label.en}
                    </option>
                  ))}
                </select>
              
              </Form.Item>

              <Form.Item
                name="en"
                label="Enter job title in English"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="hi"
                label="Enter job title in Hindi"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="ta"
                label="Enter job title in Tamil"
                rules={[{ required: true, message: 'This filed is required' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Enter Job Title In Hinglish"
                rules={[{ required: true, message: 'This filed is required' }]}
                name="hin"
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Add New Job Title
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <DataTable
              usersData={currentTitles}
              pagination={false}
              columns={[
                {
                  key: '0',
                  title: '-',
                  width: 20,
                  render: (item) => (
                    <MinusCircleFilled
                      style={{ fontSize: 18 }}
                      onClick={() => handleCurrentTitles(item.label.en)}
                    />
                  ),
                },
                {
                  key: '1',
                  title: 'Job Category',
                  render: (item) => item.industryLabel[0],
                },
                {
                  key: '2',
                  title: 'English',
                  render: (item) => item.label.en,
                },
                {
                  key: '3',
                  title: 'Hindi',
                  render: (item) => item.label.hi,
                },
                {
                  key: '4',
                  title: 'Tamil',
                  render: (item) => item.label.ta,
                },
                {
                  key: '4',
                  title: 'Hinglish',
                  render: (item) => item.label.hin,
                },
              ]}
            />
            <br />
            <Button
              disabled={!currentTitles.length > 0}
              onClick={() => uploadTitles()}
              type="primary"
            >
              Save Job Titles
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        footer=""
        title="Edit Job Title"
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        width={700}
      >
        {/* <Form layout="vertical" onFinish={updateIndustries}> */}
        <Form.Item
          label="Enter Job Title In English"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="en"
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                en: e.target.value,
              }));
            }}
            value={labels?.en}
          />
        </Form.Item>

        <Form.Item
          label="Enter Job Title In Hindi"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="hi"
            value={labels?.hi}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                hi: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          label="Enter Job Title In Tamil"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="ta"
            value={labels?.ta}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                ta: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item
          label="Enter Job Title In Hinglish"
          rules={[{ required: true, message: 'This filed is required' }]}
        >
          <Input
            name="hin"
            value={labels?.hin}
            onChange={(e) => {
              setLabels((lbl) => ({
                ...lbl,
                hin: e.target.value,
              }));
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => updateJobTitles()}
            htmlType="submit"
          >
            Update Job Category
          </Button>
        </Form.Item>
        {/* </Form> */}
      </Modal>
    </>
  );
};

export { JobTitles };
