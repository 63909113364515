import React from 'react';
import { useCSVDownloader } from 'react-papaparse';

const CSV = (props) => {
  const { filename, data } = props;
  const { CSVDownloader } = useCSVDownloader();
  return (
    <CSVDownloader filename={filename} data={data}>
      <div className='text-white'>Download File</div>
    </CSVDownloader>
  );
};

export { CSV };
