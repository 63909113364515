import React, { useState, useEffect } from 'react';
import { Form, Card, InputNumber, message, Button } from 'antd';
import { request } from '../service/common';

const SalaryMultiPlier = () => {
  // const [loading, setLoading] = useState(false);

  const [multiplier, setMultiplier] = useState();

  const fetchData = () => {
    // setLoading(true);
    request('/api/salary/multiplier', 'GET')
      .then((data) => {
        setMultiplier(data.multiplier);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
    return () => {
      setMultiplier();
    }
  }, []);

  const changeMultiplier = (values) => {
    const multi = parseInt(values.multiplier, 10);
    request('/api/salary/multiplier', 'PATCH', { data: { multiplier: multi } })
      .then(() => {
        setMultiplier(values.multiplier);
        message.success('Multiplier Updated');
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card title="Salary Multiplier">
      <h3>Current Multiplier : {multiplier} </h3> <br />
      <Form layout="inline" onFinish={changeMultiplier}>
        <Form.Item
          key="multiplier"
          initialValue={multiplier}
          label=""
          name="multiplier"
          rules={[{ required: true, message: 'Enter a valid multiplier' }]}
        >
          <InputNumber style={{ width: 200 }} key="multiplier" />
        </Form.Item>
        <Form.Item key="btn">
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export { SalaryMultiPlier };
