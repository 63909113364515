/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { VarificationStatus } from '../const';
import { request } from '../service/common';
import { CSV } from './CSV/CSV';

const ExportCSV = () => {
  const { reqData } = useParams();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState();
  const [disable, setDisable] = useState(true);
  const [fileName, setFileName] = useState();

  useEffect(() => {
    getAllUserData();
  }, []);

  const getAllUserData = () => {
    setDisable(true);
    setLoader(`Your ${reqData} data is being processed please wait......`);

    request(`/api/getAll/${reqData}`, 'GET')
      .then((data) => {
        exportToCSV(data);
      })
      .catch((error) => console.log(error));
  };

  const exportToCSV = (data) => {
    if (reqData === 'users') {
      setFileName('Users');
      setData(
        data.map((user) => {
          const updatedUser = { ...user };
          updatedUser.role =
            updatedUser.role === 1
              ? 'Labour'
              : updatedUser.role === 2
              ? 'Middleman'
              : updatedUser.role === 3
              ? 'Mill owner'
              : 'Partially registered';
          updatedUser.gender = updatedUser.gender === 1 ? 'Male' : 'Female';
          updatedUser.locale =
            updatedUser.locale === 3 ? 'ta' : updatedUser.locale === 2 ? 'hi' : 'en';
          updatedUser.userId = updatedUser.id;
          delete updatedUser.labour;
          delete updatedUser.activeOnApp;
          delete updatedUser.bannedOn;
          delete updatedUser.updatedAt;
          return updatedUser;
        }),
      );
    } else if (reqData === 'workers') {
      setFileName('Workers');
      setData(
        data.map((worker) => {
          const updatedWorker = { ...worker };

          updatedWorker.name = updatedWorker.userInfo.name;
          updatedWorker.phone = updatedWorker.userInfo.phone;
          updatedWorker.millName = updatedWorker.mill ? updatedWorker.mill.millName : 'N/A';
          updatedWorker.isBanned = !updatedWorker.userInfo.isBanned ? 'false' : 'true';
          updatedWorker.workerId = updatedWorker.id;
          delete updatedWorker.userId;
          delete updatedWorker.millId;
          delete updatedWorker.updatedAt;
          delete updatedWorker.userInfo;
          delete updatedWorker.mill;
          return updatedWorker;
        }),
      );
    } else if (reqData === 'labourers') {
      setFileName('Labourers');
      setData(
        data.map((labour) => {
          const updatedLabour = { ...labour };
          console.table(labour);
          updatedLabour.expLevel = ``.concat(`${updatedLabour.labour?.expLevel} years`);
          updatedLabour.gender = updatedLabour.gender === 1 ? 'Male' : 'Female';
          updatedLabour.locale =
            updatedLabour.locale === 3 ? 'ta' : updatedLabour.locale === 2 ? 'hi' : 'en';
          updatedLabour.skills = updatedLabour.labour?.skills || 'N/A';
          if (updatedLabour.bankDetails !== undefined) {
            if (updatedLabour.bankDetails?.aadhaarNo)
              updatedLabour.aadhaarNo = `=""`.concat(updatedLabour.bankDetails?.aadhaarNo, `""`);
            if (updatedLabour.bankDetails?.bankAccountNo)
              updatedLabour.bankAccountNo = `=""`.concat(
                updatedLabour.bankDetails?.bankAccountNo,
                `""`,
              );
            if (updatedLabour.bankDetails?.ifscCode)
              updatedLabour.ifscCode = updatedLabour.bankDetails?.ifscCode;
            if (updatedLabour.bankDetails?.backAadhaarPhoto)
              updatedLabour.backAadhaarPhoto = updatedLabour.bankDetails?.backAadhaarPhoto;
            if (updatedLabour.bankDetails?.frontAadhaarPhoto)
              updatedLabour.frontAadhaarPhoto = updatedLabour.bankDetails?.frontAadhaarPhoto;
          }
          updatedLabour.isBanned = !updatedLabour.isBanned ? 'false' : 'true';
          updatedLabour.labourId = updatedLabour.id;
          delete updatedLabour.labour;
          delete updatedLabour.bankDetails;
          delete updatedLabour.role;
          return updatedLabour;
        }),
      );    
    } else if (reqData === 'middlemen') {
      setFileName('Agents');
      setData(
        data.map((middleman) => {
          const updatedMiddleman = { ...middleman };
          updatedMiddleman.gender = updatedMiddleman.gender === 1 ? 'Male' : 'Female';
          updatedMiddleman.locale =
            updatedMiddleman.locale === 3 ? 'ta' : updatedMiddleman.locale === 2 ? 'hi' : 'en';
          updatedMiddleman.initialNoOfLabourers = updatedMiddleman.middleman?.initialNoOfLabourers;
          updatedMiddleman.states = updatedMiddleman.middleman?.states;
          if (updatedMiddleman.bankDetails !== undefined) {
            if (updatedMiddleman.bankDetails?.aadhaarNo)
              updatedMiddleman.aadhaarNo = `=""`.concat(
                updatedMiddleman.bankDetails?.aadhaarNo,
                `""`,
              );
            if (updatedMiddleman.bankDetails?.bankAccountNo)
              updatedMiddleman.bankAccountNo = `=""`.concat(
                updatedMiddleman.bankDetails?.bankAccountNo,
                `""`,
              );
            if (updatedMiddleman.bankDetails?.ifscCode)
              updatedMiddleman.ifscCode = updatedMiddleman.bankDetails?.ifscCode;
            if (updatedMiddleman.bankDetails?.backAadhaarPhoto)
              updatedMiddleman.backAadhaarPhoto = updatedMiddleman.bankDetails?.backAadhaarPhoto;
            if (updatedMiddleman.bankDetails?.frontAadhaarPhoto)
              updatedMiddleman.frontAadhaarPhoto = updatedMiddleman.bankDetails?.frontAadhaarPhoto;
          }
          updatedMiddleman.isBanned = !updatedMiddleman.isBanned ? 'false' : 'true';
          updatedMiddleman.agentId = updatedMiddleman.id;
          delete updatedMiddleman.role;
          delete updatedMiddleman.middleman;
          delete updatedMiddleman.bankDetails;
          return updatedMiddleman;
        }),
      );
    } else if (reqData === 'middleman-labourers') {
      setFileName('Agent-Labourers');
      setData(
        data.map((labour) => {
          const updatedLabour = { ...labour };
          updatedLabour.agentId = updatedLabour.id;
          updatedLabour.labourId = updatedLabour.labour.id;
          updatedLabour.expLevel = ``.concat(`${updatedLabour.labour.expLevel} years`);
          updatedLabour.gender = updatedLabour.gender === 1 ? 'Male' : 'Female';
          updatedLabour.locale =
            updatedLabour.locale === 4
              ? 'hin'
              : updatedLabour.locale === 3
              ? 'ta'
              : updatedLabour.locale === 2
              ? 'hi'
              : 'en';
          if (updatedLabour.bankDetails !== undefined) {
            if (updatedLabour.bankDetails?.aadhaarNo)
              updatedLabour.aadhaarNo = `=""`.concat(updatedLabour.bankDetails?.aadhaarNo, `""`);
            if (updatedLabour.bankDetails?.bankAccountNo)
              updatedLabour.bankAccountNo = `=""`.concat(
                updatedLabour.bankDetails?.bankAccountNo,
                `""`,
              );
            if (updatedLabour.bankDetails?.ifscCode)
              updatedLabour.ifscCode = updatedLabour.bankDetails?.ifscCode;
            if (updatedLabour.bankDetails?.backAadhaarPhoto)
              updatedLabour.backAadhaarPhoto = updatedLabour.bankDetails?.backAadhaarPhoto;
            if (updatedLabour.bankDetails?.frontAadhaarPhoto)
              updatedLabour.frontAadhaarPhoto = updatedLabour.bankDetails?.frontAadhaarPhoto;
          }
          updatedLabour.isBanned = !updatedLabour.isBanned ? 'false' : 'true';

          updatedLabour.labourId = updatedLabour.id;
          delete updatedLabour.labour;
          delete updatedLabour.bankDetails;
          delete updatedLabour.role;
          return updatedLabour;
        }),
      );
    } else if (reqData === 'mills') {
      setFileName('Factories');
      setData(
        data.map((factory) => {
          const updatedFactory = { ...factory };
          updatedFactory.phone = updatedFactory.millOwner?.userInfo?.phone;
          updatedFactory.millOwnerName = updatedFactory.millOwner?.userInfo?.name;
          updatedFactory.millId = updatedFactory.id;
          updatedFactory.isBanned = !updatedFactory.isBanned ? 'false' : 'true';
          delete updatedFactory.millOwner;
          return updatedFactory;
        }),
      );
    } else if (reqData === 'mapped-users') {
      setFileName('MappedUserData');
      setData(
        data.map((data) => {
          const updatedData = { ...data };

          updatedData.id = data.authentication.firebaseUserId;
          updatedData.role =
            updatedData.role === 1
              ? 'Labour'
              : updatedData.role === 2
              ? 'Agent'
              : updatedData.role === 3
              ? 'Mill Owner'
              : 'N/A';
          updatedData.gender =
            updatedData.gender === 1 ? 'Male' : updatedData.gender === 2 ? 'Female' : 'N/A';
          updatedData.locale =
            updatedData.locale === 1
              ? 'En'
              : updatedData.locale === 2
              ? 'Hi'
              : updatedData.locale === 3
              ? 'Ta'
              : 'N/A';
          delete updatedData.authentication;
          delete updatedData.labour;
          delete updatedData.middleman;
          delete updatedData.millOwner;
          delete updatedData.bankDetails;
          delete updatedData.imageUrl;
          updatedData.isBanned = !updatedData.isBanned ? 'false' : 'true';
          return updatedData;
        }),
      );
    } else if (reqData === 'jobs') {
      setFileName('Jobs');
      setData(
        data.map((job) => {
          const updatedJob = { ...job };
          updatedJob.expReq = ``.concat(updatedJob.expReq, ` years`);
          updatedJob.workingHours = ``.concat(updatedJob.workingHours, ` hours`);
          updatedJob.addedOn = updatedJob.addedOn?.substring(0, 10);
          updatedJob.jobArea = updatedJob.mill.area;
          updatedJob.jobCity = updatedJob.mill.city;
          updatedJob.jobState = updatedJob.mill.state;
          updatedJob.millName = updatedJob.mill.millName;
          updatedJob.genderPreference = updatedJob.genderPreference === 1 ? 'Male' : 'Female';
          updatedJob.typeOfLabour = updatedJob.typeOfLabour === 1 ? 'Skilled' : 'UnSkilled';
          updatedJob.workType = updatedJob.workType === 1 ? 'Full-time' : 'Part-time';
          updatedJob.isBanned = !updatedJob.isBanned ? 'false' : 'true';
          delete updatedJob.createdAt;
          delete updatedJob.updatedAt;
          delete updatedJob.mill;
          delete updatedJob.millId;
          return updatedJob;
        }),
      );
    } else if (reqData === 'job-applications') {
      setFileName('Applications');
      setData(
        data.map((application) => {
          console.log(application, 'This is application');
          const updatedApplication = { ...application };
          updatedApplication.labourerName = updatedApplication.labour?.userInfo?.name;
          updatedApplication.byMiddleman = updatedApplication.middleman ? 'true' : 'false';
          updatedApplication.phone = updatedApplication.middleman
            ? updatedApplication.middleman?.userInfo?.phone
            : updatedApplication.labour?.userInfo?.phone;
          updatedApplication.jobTitle = updatedApplication.job?.jobTitle;
          updatedApplication.status =
            updatedApplication.status === 1
              ? 'Pending'
              : updatedApplication.status === 2
              ? 'In Review'
              : updatedApplication.status === 3
              ? 'ShortListed'
              : updatedApplication.status === 4
              ? 'Accepted'
              : 'Rejected';
          updatedApplication.createdAt = updatedApplication.createdAt?.substring(0, 10);
          updatedApplication.middlemanName = !updatedApplication.middleman
            ? 'NA'
            : updatedApplication.middleman?.userInfo?.name;
          updatedApplication.millOwnerName =
            updatedApplication.job?.mill?.millOwner?.userInfo?.name;
          updatedApplication.millOwnerPhone =
            updatedApplication.job?.mill?.millOwner?.userInfo?.phone;
          updatedApplication.millName = updatedApplication.job?.mill?.millName;
          updatedApplication.address = updatedApplication.job?.mill?.address;
          updatedApplication.area = updatedApplication.job?.mill?.area;
          updatedApplication.city = updatedApplication.job?.mill?.city;
          updatedApplication.state = updatedApplication.job?.mill?.state;
          updatedApplication.industryType = updatedApplication.job?.mill?.industryType;

          delete updatedApplication.updatedAt;
          delete updatedApplication.jobLeftReason;
          delete updatedApplication.job;
          delete updatedApplication.labour;
          delete updatedApplication.middleman;
          return updatedApplication;
        }),
      );
    } else if (reqData === 'referrals') {
      setFileName('Referrals');
      setData(
        data.map((referral) => {
          const updatedReferral = { ...referral };
          updatedReferral.referrerName = updatedReferral.referrer?.name;
          updatedReferral.referrerPhone = updatedReferral.referrer?.phone;
          updatedReferral.saathiName = updatedReferral.saathi?.name;
          updatedReferral.saathiPhone = updatedReferral.saathi?.phone;
          updatedReferral.status =
            updatedReferral.status === 1
              ? 'Referred'
              : updatedReferral.status === 2
              ? 'Interested'
              : updatedReferral.status === 3
              ? 'Working'
              : updatedReferral.status === 4
              ? 'Eligible'
              : updatedReferral.status === 5
              ? 'Completed'
              : updatedReferral.status === 6
              ? 'Rejected'
              : 'Referred';
          updatedReferral.createdAt = updatedReferral.createdAt?.substring(0, 10);
          delete updatedReferral.referrer;
          delete updatedReferral.saathi;
          delete updatedReferral.updatedAt;
          delete updatedReferral.bannedOn;
          delete updatedReferral.isBanned;
          return updatedReferral;
        }),
      );
    } else if (reqData === 'inactive-users') {
      setFileName('Inactive-users');
      setData(
        data.map((user) => {
          const updatedUser = { ...user };
          updatedUser.registerationCompleted = !updatedUser.registerationCompleted
            ? 'false'
            : 'true';
          updatedUser.gender = updatedUser.gender === 2 ? 'Female' : 'Male';
          updatedUser.isBanned = !updatedUser.isBanned ? 'false' : 'true';
          if (updatedUser.role === 1) updatedUser.role = 'Labour';
          else if (updatedUser.role === 2) updatedUser.role = 'Agent';
          else if (updatedUser.role === 3) {
            updatedUser.role = !updatedUser?.millOwner?.millInfo
              ? 'Mill Owner without mill'
              : 'Mill Owner';
          } else {
            updatedUser.role = 'Partially Registered';
          }
          delete updatedUser.millOwner;
          delete updatedUser.bankDetails;
          delete updatedUser.labour;
          delete updatedUser.middleman;
          delete updatedUser.failedNotifications;
          delete updatedUser.id;
          delete updatedUser.locale;
          return updatedUser;
        }),
      );
    } else if (reqData === 'server-availability') {
      setFileName('ServerAvailability');
      setData(
        data.map((record) => {
          const updatedRecord = { ...record };
          updatedRecord.status = updatedRecord.code.toString(10)[0] === '5' ? 'Down' : 'Up';
          updatedRecord.timeStamp = updatedRecord.createdAt;
          delete updatedRecord.code;
          delete updatedRecord.createdAt;
          return updatedRecord;
        }),
      );
    } else if (reqData === 'location-history') {
      setFileName('Location-History');
      setData(
        data.map((history) => {
          const updatedHistory = { ...history };
          return updatedHistory;
        }),
      );
    } else if (reqData === 'sessions') {
      setFileName('Sessions');
      setData(
        data.map((session) => {
          const updatedSession = { ...session };

          // updatedSession.btnClick = btnData.stringify;
          updatedSession.StatTime = new Date(updatedSession.createdAt).toLocaleString();
          updatedSession.duration = `${
            updatedSession.endTime
              ? Math.floor(
                  (moment(updatedSession.endTime).diff(
                    moment(new Date(updatedSession.createdAt).toISOString()),
                  ) %
                    60000) /
                    10000,
                )
              : 0
          } Min`;
          updatedSession.EndTime = updatedSession.endTime
            ? new Date(updatedSession.endTime).toLocaleString()
            : 'N/A';
          updatedSession.buttonClicks
            ? Object.entries(updatedSession.buttonClicks).map((btnClick) => {
                if (btnClick[1] !== 0) {
                  // eslint-disable-next-line prefer-destructuring
                  updatedSession[btnClick[0]] += btnClick[1];
                }
              })
            : null;
          delete updatedSession.createdAt;
          delete updatedSession.endTime;
          delete updatedSession.buttonClicks;
          return updatedSession;
        }),
      );
    } else if (reqData === 'partner-mills') {
      setFileName('Partner-Mills');
      setData(
        data.map((pMills) => {
          const updatedMills = { ...pMills };
          updatedMills.verified = VarificationStatus[updatedMills.varified];
          delete updatedMills.varified;
          delete updatedMills.millOwner;
          delete updatedMills.payRelease;
          return updatedMills;
        }),
      );
    }

    setLoader(`Your ${reqData} file is ready to download!`);
    setDisable(false);
  };

  return (
    <div className="m-5">
      <div>{loader}</div>
      {!disable ? (
        <button type="button" className="w-44 border border-black bg-blue-500 mt-2">
          <CSV filename={fileName} data={data} /> Download
        </button>
      ) : null}
    </div>
  );
};

export { ExportCSV };
