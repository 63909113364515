import {
  DashboardOutlined,
  UserOutlined,
  PicCenterOutlined,
  BuildOutlined,
  MobileOutlined,
  MoneyCollectOutlined,
  // MoneyCollectFilled,
  MessageOutlined,
  PicRightOutlined,
  SettingOutlined,
  FileImageOutlined,
  LogoutOutlined,
  SwitcherOutlined,
  BellOutlined,
  // SettingFilled,
  AndroidOutlined,
  GlobalOutlined,
  AppstoreAddOutlined,
  PhoneFilled,
  VideoCameraOutlined,
} from '@ant-design/icons';

export const SidebarData = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    icon: DashboardOutlined,
    link: '/',
  },
  {
    key: 'users',
    title: 'Users',
    icon: UserOutlined,
    link: '/user',
    // isPermissionBased: true,
  },
  {
    key: 'workers',
    title: 'Workers',
    icon: UserOutlined,
    link: '/worker',
    // isPermissionBased: true,
  },
  {
    key: 'labourers',
    title: 'Labourers',
    icon: UserOutlined,
    link: '/labourer',
    isPermissionBased: true,
  },
  {
    key: 'agents',
    title: 'Agents',
    icon: PicCenterOutlined,
    link: '/agent',
    isPermissionBased: true,
  },
  {
    key: 'agents',
    title: `Agent's Labourers`,
    icon: UserOutlined,
    link: '/agent-labourer',
    isPermissionBased: true,
  },
  {
    key: 'factoryOwners',
    title: 'Factories',
    icon: BuildOutlined,
    link: '/factory',
    isPermissionBased: true,
  },

  {
    key: 'Partners Mills',
    title: 'Partner Mills',
    icon: BuildOutlined,
    link: '/partner-mills',
    // isPermissionBased: true,
  },
  {
    key: 'appData',
    title: 'App Data',
    icon: MobileOutlined,
    link: '/app-data',
  },
  {
    key: 'appversion',
    title: 'App Version',
    icon: AndroidOutlined,
    link: '/version',
  },

  {
    key: 'companyData',
    title: 'Company Data',
    icon: AndroidOutlined,
    link: '/company-data',
  },
  {
    key: 'MapData',
    title: 'Mapped Data',
    icon: MobileOutlined,
    link: '/mapped-users',
  },
  {
    key: 'Location',
    title: 'Location History',
    icon: GlobalOutlined,
    link: '/location-history',
  },
  {
    key: 'Bucket',
    title: 'Bucket',
    icon: AppstoreAddOutlined,
    link: '/bucket',
  },
  {
    key: 'jobPosts',
    title: 'Jobs',
    icon: SwitcherOutlined,
    link: '/job',
    isPermissionBased: true,
  },
  {
    key: 'jobApplications',
    title: 'Job Approvals',
    icon: PicRightOutlined,
    link: '/approval',
    isPermissionBased: true,
  },
  {
    key: 'systemTags',
    title: 'System Tags',
    icon: SettingOutlined,
    link: '/systemTags',
  },
  {
    key: 'jobMarkets',
    title: 'Job Markets',
    icon: MobileOutlined,
    link: '/JobMarkets',
  },
  {
    key: 'referrals',
    title: 'Referrals',
    icon: PicRightOutlined,
    link: '/referral',
    // isPermissionBased: true,
  },
  {
    key: 'Billing',
    title: 'Billing & Invoice',
    icon: MoneyCollectOutlined,
    link: '/billing',
  },
  {
    key: 'calllogs',
    title: 'Call Logs',
    icon: PhoneFilled,
    link: '/call-details',
  },
  // {
  //   key: 'industryTypes',
  //   title: 'Industry Types',
  //   icon: SwitcherOutlined,
  //   link: '/milltype',
  // },
  {
    key: 'jobCatagories',
    title: 'Job Categories',
    icon: SwitcherOutlined,
    link: '/jobCategories',
  },
  {
    key: 'notification',
    title: 'Notifications',
    icon: BellOutlined,
    link: '/notification',
    isPermissionBased: true,
  },

  {
    key: 'pNotification',
    title: 'Personalised Notifications',
    icon: BellOutlined,
    link: '/personalised-notification',
    // isPermissionBased: true,
  },

  {
    key: 'eNotification',
    title: 'Event Notifications',
    icon: BellOutlined,
    link: '/event-notification',
    // isPermissionBased: true,
  },
  {
    key: 'inactiveUsers',
    title: 'Inactive Users',
    icon: SwitcherOutlined,
    link: '/inactives',
  },

  {
    key: 'uninstalls',
    title: 'Uninstalls',
    icon: SwitcherOutlined,
    link: '/uninstalls',
  },
  {
    key: 'videos',
    title: 'In App Videos',
    icon: VideoCameraOutlined,
    link: '/videos',
  },
  {
    key: 'flyers',
    title: 'Flyers',
    icon: FileImageOutlined,
    link: '/flyers',
  },
  {
    key: 'support',
    title: 'Support',
    icon: MessageOutlined,
    link: '/support',
  },
  {
    key: 'adminAccess',
    title: 'Admins',
    icon: SettingOutlined,
    link: '/admin',
    isPermissionBased: true,
  },
  {
    key: 'server-availability',
    title: 'Uptime/Downtime',
    icon: SettingOutlined,
    link: '/server-availability',
  },
  {
    key: 'logs',
    title: 'Logs',
    icon: SettingOutlined,
    link: '/logs',
  },
  {
    key: 'session',
    title: 'Session',
    icon: SettingOutlined,
    link: '/session',
  },
  {
    key: 'logout',
    title: 'Logout',
    icon: LogoutOutlined,
    link: '/logout',
  },
];
