/* eslint-disable no-unused-expressions */
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  PhoneFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Col, Input, Row, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import QS from 'query-string';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { DataTable } from './Table/Table';

const CallDetails = () => {
  const [loading, setLoading] = useState(true);
  const [totalCalls, setTotalCalls] = useState(true);
  const [connectedCalls, setConnectedCalls] = useState(true);
  const [rejectedCalls, setRejectedCalls] = useState(true);
  const [callLogs, setCallLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [filters, setFilters] = useState({});

  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);

  const getCallLogs = () => {
    setLoading(true);
    request('/api/callHr/call-details?size=1000000', 'GET')
      .then((callDetails) => {
        setCallLogs(callDetails);
        setTotalCalls(callLogs.length);
        setConnectedCalls(callLogs.filter((log) => log.isCallConnected).length);
        setRejectedCalls(callLogs.filter((log) => !log.isCallConnected).length);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const refreshTable = (queryString) => {
    setLoading(true);

    request(
      queryString
        ? `/api/callHr/call-details?page=${page}&${queryString}`
        : `/api/callHr/call-details?page=${page}`,
      'GET',
    )
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.key = item.id;
          });
          setCallLogs(data);
        } else {
          if (page === 1) setCallLogs(data);
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => getCallLogs(), []);

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  useEffect(() => {
    refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
  }, [page]);

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
  }, [isFilterChanged]);

  const onTableFilterChange = (query) => {
    setFilters({ ...filters, ...query });
  };

  const clearFilter = (type) => {
    setPage(1);
    setFilters({ ...filters, [type]: undefined });
    setIsFilterChanged(true);
  };

  const setFilterChange = () => {
    setIsFilterChanged(true);
  };

  const cols = [
    {
      key: 1,
      title: 'Caller',
      render: (record) => record.user.name,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.caller}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  caller: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>

          <Col>
            <Button
              onClick={() => clearFilter('caller')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 2,
      title: 'Mill Name',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => <Tooltip title={record.mill.millName}> {record.mill.millName}</Tooltip>,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.millName}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  millName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>

          <Col>
            <Button
              onClick={() => clearFilter('millName')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 2,
      title: 'Reciever',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <Tooltip title={record.mill.millOwner.userInfo.name}>
          {' '}
          {record.mill.millOwner.userInfo.name}
        </Tooltip>
      ),
    },
    {
      key: 3,
      title: 'Job',
      render: (record) => record.job.jobTitle,
    },
    {
      key: 4,
      title: 'Status',
      ellipsis: {
        showTitle: false,
      },
      render: (record) => (
        <center>
          <PhoneFilled style={{ color: record.isCallConnected ? '#5CB85C' : '#D9534F' }} />
        </center>
      ),
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  status: true,
                });
                setFilterChange();
              }}
              icon={<CaretUpOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>

          <Col>
            <Button
              onClick={() => {
                setPage(1);
                onTableFilterChange({
                  status: false,
                });
                setFilterChange();
              }}
              icon={<CaretDownOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('status')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      width: 100,
    },
    {
      key: 5,
      title: 'Date',
      render: (record) => record.createdAt,
    },
  ];

  return (
    <HCLayout title="Call Details">
      <Row gutter={24} className="p-3">
        <Col xs={24} md={8} sm={12} lg={8} className="gutter-row ">
          <div className="tileStyle">
            <h2>Total Calls</h2>
            <span>{totalCalls}</span>
          </div>
        </Col>
        <Col xs={24} md={8} sm={12} lg={8} className="gutter-row">
          <div className="tileStyle">
            <h2>Total Connected Calls</h2>
            <span>{connectedCalls}</span>
          </div>
        </Col>

        <Col xs={24} md={8} sm={12} lg={8}>
          <div className="tileStyle">
            <h2>Total Rejected Calls</h2>
            <span>{rejectedCalls}</span>
          </div>
        </Col>
      </Row>
      <DataTable columns={cols} usersData={callLogs} pagination={false} loading={loading} />

      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
      <br />
    </HCLayout>
  );
};

export { CallDetails };
