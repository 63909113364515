/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable dot-notation */
/* eslint-disable no-constant-condition */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Select, Row, Col, Tabs, Card, message } from 'antd';
import { BellFilled, DeleteFilled } from '@ant-design/icons';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import './Layout/style.css';
import { ROLE } from './Hepler';

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
export const PersonalisedNotification = () => {
  const [notification, setNotification] = useState([]);

  const [title, setTitle] = useState('');

  const [url, setUrl] = useState('');

  const [body, setBody] = useState('');
  const [templates, setTemplates] = useState([]);
  const [tamplateBody, setTemplateBody] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateId, setTemplateId] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);

  const [selectedRole, setSelectedRole] = useState('');

  const handleTamplate = (e) => {
    setTemplateBody(e.target.value);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setNotification();
    };
  }, []);

  const fetchData = (pageParam) => {
    request(
      pageParam === undefined || pageParam === null
        ? '/api/templates'
        : `/api/templates${pageParam}`,
      'GET',
    )
      .then(async (data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          item.key = item.id;
        });
        setTemplates(data);
      })
      .catch((err) => {
        throw err;
      });
  };

  const createTamplate = () => {
    request('/api/templates', 'POST', {
      data: {
        title: templateTitle,
        template: tamplateBody,
      },
    }).then((res) => {
      setTemplates([...templates, res]);
      console.log(res);
    });
  };

  const sendNotification = () => {
    if (!selectedRole) {
      message.warn('Please select a role');
      return;
    }

    const notif = {
      url,
      role: selectedRole,
      templateId,
    };

    request(`/api/notification/personalised`, 'POST', {
      data: notif,
    })
      .then(async () => {
        setModalVisibility(false);
        setTitle('');
        setBody('');
      })
      .catch((err) => {
        setModalVisibility(false);
        setTitle('');
        setBody('');
        throw err;
      });
  };

  const cancelNotification = () => {
    setModalVisibility(false);
    setUrl('');
    setSelectedRole('');
    setTemplateId('');
  };

  const onDelete = (record) => {
    Modal.confirm({
      title: 'Are you sure, you want to delete this template ?',
      okText: 'Yes, Delete',
      onOk: () => {
        request(`/api/templates/${record.id}`, 'DELETE')
          .then(async () => {
            setTemplates(templates.filter((template) => record.id !== template.id));
          })
          .catch((err) => {
            throw err;
          });
      },
    });
  };

  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Notifications"
      // actions={selectedRole !== null ? actionBtn : null}
    >
      <Tabs key={1} defaultActiveKey={1}>
        <TabPane tab="Templates" key={1}>
          <Row gutter={[16, 16]}>
            {templates.map((template) => (
              <Col sm={12} lg={6}>
                <Card
                  title={template.title}
                  // actions={[<b>Created At: {template.createdAt}</b>]}
                  extra={
                    <Row gutter={[4, 4]}>
                      <Col>
                        <Button
                          onClick={() => {
                            setModalVisibility(true);
                            setTemplateId(template.id);
                          }}
                          type="primary"
                        >
                          <BellFilled />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => onDelete(template.id)}
                          style={{ background: 'crimson', border: 0 }}
                          type="primary"
                        >
                          <DeleteFilled />
                        </Button>
                      </Col>
                    </Row>
                  }
                  style={{ height: 200 }}
                >
                  <p>Template Id : {template.id}</p>
                  <p>{template.template}</p>
                </Card>
              </Col>
            ))}
          </Row>

          <Modal
            title="Send New Personalised Notification"
            visible={modalVisibility}
            onCancel={cancelNotification}
            onOk={sendNotification}
          >
            <label> Image URL</label> <br />
            <Input
              type="text"
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              placeholder="Enter URL"
            />
            <br />
            <br />
            <label>Send To :</label>
            <Select
              defaultValue="Please select a role"
              style={{ width: '100%' }}
              onChange={(e) => {
                console.log(e);
                setSelectedRole(e);
              }}
            >
              <Option value={ROLE.Labour}>Labourers</Option>
              <Option value={ROLE.Middlemen}>Agent</Option>
            </Select>
          </Modal>
        </TabPane>

        <TabPane tab="Create Templates" key={3}>
          <Row>
            <Col>
              <label>Create Templates : </label> <br /> <br />
              <Input
                placeholder="Enter Title.."
                onChange={(e) => setTemplateTitle(e.target.value)}
              />
              <label>Create Templates : </label> <br /> <br />
              <TextArea
                style={{ width: 800, height: 300, padding: 20 }}
                type="text"
                placeholder="Enter Template Body..."
                onChange={(e) => {
                  handleTamplate(e);
                }}
              />
              <br />
              <br />
              <Button onClick={() => createTamplate()} type="primary">
                Send
              </Button>
            </Col>
            <Col>{tamplateBody}</Col>
          </Row>
        </TabPane>
      </Tabs>
    </HCLayout>
  );
};
