/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Button, Row, Col } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { HCLayout } from './Layout/HCLayout';
import { RefImg } from './RefImg';
import { SalaryMultiPlier } from './salaryMultiPlier';
import { Tags } from './Tags';

const AppData = () => {
  const actionBtn = [
    <Button
      key="11"
      type="primary"
      onClick={() => {
        window.location.reload();
      }}
    >
      <ReloadOutlined />
    </Button>,
  ];
  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="App Data"
      actions={actionBtn}
    >
      <Row gutter={[24]} key="1">
        <Col span={12}>
          <Tags />
        </Col>
        <Col span={12}>
          <SalaryMultiPlier />
          <br />
          <RefImg />
        </Col>
      </Row>
    </HCLayout>
  );
};

export { AppData };
