/* eslint-disable no-param-reassign */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Modal, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { request } from '../service/common';
import { HCLayout } from './Layout/HCLayout';
import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';

import { DataTable } from './Table/Table';

const AppVersion = () => {
  const [appVersion, setAppVersion] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVersion = () => {
    setLoading(true);
    request('/api/appversion', 'GET').then(async (res) => {
      // eslint-disable-next-line array-callback-return
      res.map((item) => {
        item.key = item.id;
      });
      setAppVersion(res);
      setLoading(false);
    });
  };

  const uploadVersion = (e) => {
    setLoading(true);
    console.log(e);
    request('/api/appversion', 'POST', { data: e })
      .then((res) => {
        console.log(res);
        setAppVersion([...appVersion, res]);
        setLoading(false);
        message.success('App Version Uploaded');
      })
      .catch((err) => console.log(err));
  };

  const deleteVersion = (id) => {
    Modal.confirm({
      title: 'Are you sure ?',
      content: 'Are you dure you want to permanently delete this version ?',
      okText: 'Yes, Delete',
      cancelText: 'No, Abort',
      onOk: () => {
        setLoading(true);
        request(`/api/appversion/${id}`, 'DELETE').then(() => {
          setLoading(false);
          setAppVersion(appVersion.filter((version) => version.id !== id));
          message.success('App Version Deleted');
        });
      },
    });
  };

  const updateVersion = (e, id) => {
    console.log(e);
    request(`/api/appversion/${id}`, 'PATCH', { data: { compulsory: e } }).then(() =>
      window.location.reload(),
    );
  };

  const cols = [
    {
      key: '1',
      title: 'Version',
      render: (record) => record.version,
    },
    {
      key: '2',
      title: 'Description',
      render: (record) => record.comment,
    },
    {
      key: '3',
      title: 'Compulsory',
      render: (record) => (
        <Switch onChange={(e) => updateVersion(e, record.id)} checked={record.compulsory} />
      ),
    },
    {
      key: '3',
      title: 'Uploaded On',
      render: (record) => <Tooltip>{record.createdAt}</Tooltip>,
    },

    {
      key: '4',
      title: 'Action',
      render: (record) => (
        <>
          <DeleteOutlined
            style={innerTableActionBtnDesign}
            onClick={() => deleteVersion(record.id)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getVersion();
    return () => {
      setAppVersion();
    }
  }, []);
  return (
    <HCLayout title="App Version">
      <Card title="Add New Version">
        <Form layout="vertical" onFinish={(e) => uploadVersion(e)}>
          <Form.Item
            label="Version"
            rules={[{ required: true, message: 'Please enter a version number' }]}
            name="version"
          >
            <Input placeholder="Enter Version Number" />
          </Form.Item>
          <Form.Item label="Description" name="comment">
            <Input placeholder="Enter Description" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Upload A New Version
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <br />
      <Card title="Version History">
        <DataTable usersData={appVersion} columns={cols} loading={loading} />
      </Card>
    </HCLayout>
  );
};

export { AppVersion };
