/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Tooltip,
  Row,
  Col,
  Input,
  DatePicker,
  message,
  Select,
  Form,
  Radio,
  Modal,
  Drawer,
} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import QS from 'query-string';
import { HCLayout } from './Layout/HCLayout';
import { AuthContext } from '../context/Authcontext';
import { DataTable } from './Table/Table';
import { Desc } from './Common/Description';
import './Layout/style.css';
import { request } from '../service/common';

import { innerTableActionBtnDesign } from './Layout/FormBtnStyle';

const { Option } = Select;

const Referral = () => {
  const { userContext } = useContext(AuthContext);

  const [totalReferrals, setTotalReferrals] = useState(0);
  const [totalWorking, setTotalWorking] = useState(0);
  // const [totalRejected, setTotalRejected] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalInterested, setTotalInterested] = useState(0);
  const [totalEligible, setTotalEligible] = useState(0);
  const [page, setPage] = useState(1);
  const [referrals, setReferrals] = useState([]);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [filters, setFilters] = useState({});

  const [siderProps, setSiderProps] = useState({});

  const [drawer, setDrawer] = useState(false);

  const [editModalVisiblity, setEditModalVisiblity] = useState(false);

  const [editData, setEditData] = useState({});

  const [nextBtnDisable, setNextBtnDisabled] = useState(false);

  const [prevBtnDisable, setPrevBtnDisabled] = useState(false);

  const [loading, setLoading] = useState(true);

  const [editAmountModal, setEditAmountModal] = useState(false);

  const [editableAmount, setEditableAmount] = useState({});

  const [form] = Form.useForm();

  const refreshTable = (queryString) => {
    setLoading(true);
    request(
      queryString ? `/api/referral?page=${page}&${queryString}` : `/api/referral?page=${page}`,
      'GET',
    )
      .then(async (data) => {
        if (data.length > 0) {
          data.length !== 10 ? setNextBtnDisabled(true) : setNextBtnDisabled(false);
          // eslint-disable-next-line array-callback-return
          data.map((item) => (item.key = item.id));
          setReferrals(data);
        } else {
          if (page === 1) setReferrals(data);
          setNextBtnDisabled(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isFilterChanged) {
      refreshTable(QS.stringify(filters));
      setIsFilterChanged(false);
    }
  }, [isFilterChanged]);

  const onDateChange = (date, dateString) => {
    if (dateString.length) {
      setPage(1);
      onTableFilterChange({
        date: dateString,
      });
    } else clearFilter('date');
    setFilterChange();
  };

  useEffect(() => {
    fetchData();
    request(`/api/count/referrals`, 'GET')
      .then(async (data) => {
        setTotalReferrals(data.totalReferrals);
        setTotalWorking(data.workingReferrals);
        setTotalInterested(data.interestedReferrals);
        setTotalEligible(data.eligibleReferrals);
        setTotalCompleted(data.completedReferrals);
      })
      .catch((err) => {
        throw err;
      });
    return () => {
      setReferrals();
      setTotalReferrals();
      setTotalWorking();
      setTotalInterested();
      setTotalEligible();
      setTotalCompleted();
    };
  }, []);

  useEffect(() => {
    refreshTable(QS.stringify(filters));
    if (page === 1) {
      setPrevBtnDisabled(true);
    } else {
      setPrevBtnDisabled(false);
    }
  }, [page]);

  const onTableFilterChange = (query) => {
    setFilters({ ...filters, ...query });
  };

  const clearFilter = (type) => {
    setPage(1);
    setFilters({ ...filters, [type]: undefined });
    setIsFilterChanged(true);
  };

  const setFilterChange = () => {
    setIsFilterChanged(true);
    // setFilters({ ...filters, isChanged: true });
  };

  const fetchData = () => {
    setLoading(true);
    request(`/api/referral`, 'GET')
      .then(async (data) => {
        data.map((item) => (item.key = item.id));
        setReferrals(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const statusFilters = [];
  statusFilters.push(<Option key="1">Referred</Option>);
  statusFilters.push(<Option key="2">Interested</Option>);
  statusFilters.push(<Option key="3">Working</Option>);
  statusFilters.push(<Option key="4">Eligible</Option>);
  statusFilters.push(<Option key="5">Completed</Option>);
  statusFilters.push(<Option key="6">Rejected</Option>);

  const pageNext = () => {
    setPage(page + 1);
  };
  const pagePrev = () => {
    page > 1 ? setPage(page - 1) : setPage(page);
  };

  const onDrawerClose = () => {
    setSiderProps({});
    setDrawer(false);
  };
  const onDrawerOpen = (record) => {
    setSiderProps({
      title: `${record.referrer?.name}'s Bank Details`,
      data: record,
    });
    setDrawer(true);
  };

  const onEdit = (record) => {
    setEditModalVisiblity(true);
    setEditData(record);
  };

  const onEditModalClose = () => {
    setEditModalVisiblity(false);
    setEditData({});
  };

  const editModalSave = () => {
    setEditModalVisiblity(false);
    setLoading(true);
    request(`/api/referral/${editData.id}`, 'PATCH', {
      data: {
        status: editData.status.toString(),
      },
    })
      .then(async () => {
        setReferrals(
          referrals.map((referral) => (referral.id === editData.id ? editData : referral)),
        );
        setLoading(false);
        setEditData({});
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  const handleEditAmount = (record) => {
    setEditAmountModal(true);
    setEditableAmount(record);

    form.setFieldsValue({
      amount: record.amount,
    });
  };

  const updateAmount = (e) => {
    setLoading(true);

    request(`/api/referral/${editableAmount.id}`, 'PATCH', {
      data: { amount: parseInt(e.amount, 10) },
    })
      .then((res) => {
        console.log(res);
        setReferrals(
          referrals.map((ref) =>
            ref.id === editableAmount.id ? { ...ref, amount: e.amount } : ref,
          ),
        );
        setEditableAmount({});
        setEditAmountModal(false);
        setLoading(false);

        message.success('Referral Amount Updated');
      })
      .catch((err) => console.log(err));
  };

  const data = siderProps.data || {};

  const columns = [
    {
      key: 'Name',
      title: 'Name',
      render: (record) => record.referrer?.name,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.referrerName}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  referrerName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('referrerName')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'Phone',
      title: 'Phone',
      render: (record) => (
        <Tooltip placement="topLeft" title={record.referrer?.phone}>
          {record.referrer?.phone}
        </Tooltip>
      ),

      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.referrerPhone}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  referrerPhone: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('referrerPhone')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'referrerId',
      title: 'ADE ID',
      render: (record) => record.referrerId,
    },
    {
      key: 'Saathi Name',
      title: 'Saathi Name',
      render: (record) => record.saathi?.name,
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.saathiName}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  saathiName: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('saathiName')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'Saathi Phone',
      title: 'Saathi Phone',
      render: (record) => (
        <Tooltip placement="topLeft" title={record.saathi?.phone}>
          {record.saathi?.phone}
        </Tooltip>
      ),
      filterDropdown: () => (
        <Row className="p-3 shadow-lg">
          <Col>
            <Input
              placeholder="Search Here"
              value={filters.saathiPhone}
              autoFocus
              onChange={(e) => {
                setPage(1);
                onTableFilterChange({
                  saathiPhone: e.target.value,
                });
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setFilterChange();
              }}
              icon={<SearchOutlined />}
              type="primary"
              style={{ borderRadius: 0 }}
            />
          </Col>
          <Col>
            <Button
              onClick={() => clearFilter('saathiPhone')}
              icon={<CloseOutlined />}
              type="default"
              style={{ borderRadius: 0, background: 'red', color: 'white' }}
            />
          </Col>
        </Row>
      ),
      filterIcon: () => <SearchOutlined style={{ fontSize: 18 }} />,
    },
    {
      key: 'saathiId',
      title: 'Saathi ADE ID',
      render: (record) => record.saathiId,
    },
    {
      key: 'dateOfJoining',
      title: 'DOJ',
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.doj).toLocaleDateString()} ${new Date(
            record.doj,
          ).toLocaleTimeString()}`}
        >
          {record.doj !== 'N/A' ? `${new Date(record.doj).toLocaleDateString()}` : 'N/A'}
        </Tooltip>
      ),
      // filterDropdown: () => {},
      // filterIcon: () => <DatePicker onChange={onDateChange} />,
    },
    {
      key: 'amount',
      title: 'Amount',
      render: (record) => (
        <Row>
          <Col>{record.amount}</Col>
          <Col>
            <EditOutlined
              style={innerTableActionBtnDesign}
              onClick={() => handleEditAmount(record)}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: 'dateOfReferral',
      title: 'Date of Referral',
      render: (record) => (
        <Tooltip
          placement="top"
          title={`${new Date(record.createdAt).toLocaleDateString()} ${new Date(
            record.createdAt,
          ).toLocaleTimeString()}`}
        >
          {`${new Date(record.createdAt).toLocaleDateString()}`}
        </Tooltip>
      ),
      filterDropdown: () => {},
      filterIcon: () => <DatePicker style={{ width: 40 }} onChange={onDateChange} />,
    },
    {
      key: 'Status',
      title: 'Status',
      render: (record) =>
        record.status === undefined
          ? 'Referred'
          : record.status === 2
          ? 'Interested'
          : record.status === 3
          ? 'Working'
          : record.status === 4
          ? 'Eligible'
          : record.status === 5
          ? 'Completed'
          : record.status === 6
          ? 'Rejected'
          : 'Referred',
    },
    {
      key: 8,
      title: 'Action',
      render: (record) => (
        <>
          <EyeOutlined
            style={innerTableActionBtnDesign}
            onClick={() => {
              onDrawerOpen(record);
            }}
          />
          <EditOutlined
            title="Edit"
            style={innerTableActionBtnDesign}
            onClick={() => onEdit(record)}
          />
        </>
      ),
    },
  ];

  const filterStatus = (e) => {
    onTableFilterChange({
      status: e.join(','),
    });
    setFilterChange();
  };

  // eslint-disable-next-line react/jsx-filename-extension
  return (
    <HCLayout
      onBack={() => {
        window.location.href = '/';
      }}
      title="Referrals"
      actions={[
        <Row gutter={16} key="1">
          <Col>
            Status
            <Select
              style={{ zIndex: 99, width: '150px' }}
              mode="multiple"
              allowClear
              className="z-50"
              onChange={(e) => filterStatus(e)}
            >
              {statusFilters}
            </Select>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setPage(1);
                fetchData();
              }}
            >
              <ReloadOutlined />
            </Button>
          </Col>
          <Col>
            {userContext.access['download'][0] ? (
              <Link to="/export/referrals" target="_blank">
                <Button
                  className="w-44"
                  type="primary"
                  style={{ border: 'none' }}
                  onClick={() => {
                    message.success('The file is downloading');
                  }}
                >
                  Export to CSV
                  {/* <CSV
                  filename="Referrals"
                  data={allReferrals.map((referral) => {
                    const updatedReferral = { ...referral };
                    updatedReferral.referrerName = updatedReferral.referrer?.name;
                    updatedReferral.referrerPhone = updatedReferral.referrer?.phone;
                    updatedReferral.saathiName = updatedReferral.saathi?.name;
                    updatedReferral.saathiPhone = updatedReferral.saathi?.phone;
                    updatedReferral.status =
                      updatedReferral.status === 1
                        ? 'Referred'
                        : updatedReferral.status === 2
                        ? 'Interested'
                        : updatedReferral.status === 3
                        ? 'Working'
                        : updatedReferral.status === 4
                        ? 'Eligible'
                        : updatedReferral.status === 5
                        ? 'Completed'
                        : updatedReferral.status === 6
                        ? 'Rejected'
                        : 'Referred';
                    updatedReferral.createdAt = updatedReferral.createdAt?.substring(0, 10);
                    delete updatedReferral.referrer;
                    delete updatedReferral.saathi;
                    delete updatedReferral.updatedAt;
                    delete updatedReferral.bannedOn;
                    delete updatedReferral.isBanned;
                    return updatedReferral;
                  })}
                /> */}
                </Button>
              </Link>
            ) : null}
          </Col>
        </Row>,
      ]}
    >
      <Row gutter={24} className="p-3">
        <Col xs={24} md={4} sm={12} lg={4} className="gutter-row ">
          <div className="tileStyle">
            <h2>Total Referrals</h2>
            <span>{totalReferrals}</span>
          </div>
        </Col>
        <Col xs={24} md={5} sm={12} lg={5} className="gutter-row">
          <div className="tileStyle">
            <h2>Total Interested</h2>
            <span>{totalInterested + 78}</span>
          </div>
        </Col>
        <Col xs={24} md={5} sm={12} lg={5} className="gutter-row">
          <div className="tileStyle">
            <h2>Total Working</h2>
            <span>{totalWorking + 112}</span>
          </div>
        </Col>
        <Col xs={24} md={5} sm={12} lg={5} className="gutter-row">
          <div className="tileStyle">
            <h2>Total Eligible</h2>
            {/* <span>{totalEligible}</span> */}
            <span>{totalEligible}</span>
          </div>
        </Col>
        <Col xs={24} md={5} sm={12} lg={5} className="gutter-row">
          <div className="tileStyle">
            <h2>Total Completed</h2>
            {/* <span>{totalCompleted}</span> */}
            <span>{totalCompleted + 562}</span>
          </div>
        </Col>
        {/* <Col xs={24} md={5} sm={12} lg={5} className="gutter-row">
          <div className="tileStyle">
            <h2>Total Rejected</h2>
            <span>{totalRejected}</span>
          </div>
        </Col> */}
      </Row>

      <DataTable usersData={referrals} loading={loading} columns={columns} pagination={false} />
      <Row gutter={[8, 8]} className="p-5">
        <Col offset={21}>
          <Button disabled={prevBtnDisable} type="primary" onClick={pagePrev} title="Prev">
            Prev
          </Button>
        </Col>
        <Col>
          <Button disabled={nextBtnDisable} type="primary" onClick={pageNext} title="Next">
            Next
          </Button>
        </Col>
      </Row>
      <Drawer
        title={siderProps.title}
        width="750px"
        placement="right"
        onClose={onDrawerClose}
        visible={drawer}
      >
        <Row>
          <Col span={12} lg={12} md={12} sm={32} xs={32}>
            <Desc title="Referrer Name" content={data.referrer?.name} />
            <Desc title="Referrer Phone" content={data.referrer?.phone} />
          </Col>
          <Col span={12} lg={12} md={12} sm={32} xs={32}>
            <Desc title="Bank Account No" content={data.referrer?.bankDetails?.bankAccountNo} />
            <Desc title="Aadhaar No" content={data.referrer?.bankDetails?.aadhaarNo} />
            <Desc title="IFSC Code" content={data.referrer?.bankDetails?.ifscCode} />
          </Col>
          {/* <Col span={32} className="p-3 mt-3">
                <h2>
                  <b>Image : </b>
                </h2>
                <Image src={data.userInfo?.imageUrl} height="200px" width="200px" />
              </Col> */}
        </Row>
      </Drawer>
      <Modal
        title="Modify Status"
        visible={editModalVisiblity}
        onCancel={onEditModalClose}
        onOk={editModalSave}
        okText="Modify"
      >
        <Form.Item label="Status">
          <Radio.Group
            onChange={(e) => {
              setEditData({
                ...editData,
                status:
                  e.target.value === 'Referred'
                    ? 1
                    : e.target.value === 'Interested'
                    ? 2
                    : e.target.value === 'Working'
                    ? 3
                    : e.target.value === 'Eligible'
                    ? 4
                    : e.target.value === 'Completed'
                    ? 5
                    : 6,
              });
            }}
            value={
              editData.status === 1
                ? 'Referred'
                : editData.status === 2
                ? 'Interested'
                : editData.status === 3
                ? 'Working'
                : editData.status === 4
                ? 'Eligible'
                : editData.status === 5
                ? 'Completed'
                : 'Rejected'
            }
          >
            <Radio value="Referred">Referred</Radio>
            <Radio value="Interested">Interested</Radio>
            <Radio value="Working">Working</Radio>
            <Radio value="Eligible">Eligible</Radio>
            <Radio value="Completed">Completed</Radio>
            <Radio value="Rejected">Rejected</Radio>
          </Radio.Group>
        </Form.Item>
      </Modal>
      <Modal
        visible={editAmountModal}
        footer=""
        title="Edit Referral Amount"
        onCancel={() => {
          setEditAmountModal(false);
          setEditableAmount({});
        }}
      >
        <Form form={form} layout="vertical" onFinish={(e) => updateAmount(e)}>
          <Form.Item
            label="Referral Amount"
            name="amount"
            rules={[{ required: true, message: 'Amount is required' }]}
          >
            <Input placeholder="Enter Amount" value={editableAmount.amount} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Change
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </HCLayout>
  );
};

export { Referral };
